import { Injectable } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { ModalService } from 'src/app/components/modals/modal.service';
import { PresentationItemComponent } from '../components/presentation-item/presentation-item.component';
import { PlaceholderPlaylistService } from './placeholder-playlist.service';
import { WidgetModalService } from './widget-modal.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private modalService: ModalService,
    private widgetModalService: WidgetModalService,
    private placeholderPlaylistService: PlaceholderPlaylistService) { }

  _showPresentationSettingsModal(item) {
    return this.modalService.showMediumDialog(PresentationItemComponent, {
      item
    });
  }

  showSettingsModal(item, softUpdate?) {
    var deferred;

    if (item && item.type === 'widget') {
      deferred = this.widgetModalService.showSettingsModal(item);
    } else if (item && item.type === 'presentation') {
      deferred = this._showPresentationSettingsModal(item);
    }

    if (!deferred) {
      return;
    }

    deferred.then(() => {
      if (!softUpdate) {
        this.placeholderPlaylistService.updateItem(item);
      }
    });

  }
}

angular.module('risevision.editor.services')
  .factory('settingsService', downgradeInjectable(SettingsService));

import { Component } from '@angular/core';


import { MatDialogRef } from '@angular/material/dialog';
import { ScheduleSelectorService } from '../../services/schedule-selector.service';

@Component({
  selector: 'add-to-schedule-modal',
  templateUrl: './add-to-schedule-modal.component.html',
  styleUrl: './add-to-schedule-modal.component.scss'
})
export class AddToScheduleModalComponent {

  constructor(
    public dialogRef: MatDialogRef<AddToScheduleModalComponent>,
    public scheduleSelectorService: ScheduleSelectorService
  ) {
  }

}

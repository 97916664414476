import { Pipe, PipeTransform } from '@angular/core';
import { StorageUtilsService } from '../services/storage-utils.service';

@Pipe({
  name: 'storageFileType'
})
export class StorageFileTypePipe implements PipeTransform {

  constructor(private storageUtilsService: StorageUtilsService) { }

  transform(filename: string): string {
    return this.storageUtilsService.getFileType(filename);
  }

}

import { Injectable } from '@angular/core';
import { StorageUtilsService } from './storage-utils.service';

import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CopyUrlModalComponent } from '../components/copy-url-modal/copy-url-modal.component';
import { FolderSelectorModalComponent } from '../components/folder-selector-modal/folder-selector-modal.component';
import { NewFolderModalComponent } from '../components/new-folder-modal/new-folder-modal.component';
import { RenameFileModalComponent } from '../components/rename-file-modal/rename-file-modal.component';
import { StorageModalComponent } from '../components/storage-modal/storage-modal.component';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storageFull: boolean = true;
  selectorType: string = '';
  selectorFilter: { name: string, extensions: string[] } = StorageUtilsService.SELECTOR_FILTERS.ALL;

  constructor(
    private modalService: ModalService,
    private storageUtilsService: StorageUtilsService
  ) { }

  reset() {
    this.storageFull = true;
    this.selectorType = '';
    this.selectorFilter = StorageUtilsService.SELECTOR_FILTERS.ALL;
  }

  private _isFilteredFile(file) {
    if (this.storageUtilsService.fileIsFolder(file)) {
      return false;
    }

    let filtered = false;

    if (this.selectorFilter.extensions) {
      filtered = !this.storageUtilsService.fileHasExtension(file, this.selectorFilter.extensions);
    }

    return filtered;
  }

  setSelectorType(type, filter) {
    this.selectorType = StorageUtilsService.SELECTOR_TYPES.SINGLE_FILE;
    this.selectorFilter = StorageUtilsService.SELECTOR_FILTERS.ALL;

    for (let j in StorageUtilsService.SELECTOR_TYPES) {
      if (type === StorageUtilsService.SELECTOR_TYPES[j]) {
        this.selectorType = StorageUtilsService.SELECTOR_TYPES[j];

        break;
      }
    }

    for (let k in StorageUtilsService.SELECTOR_FILTERS) {
      if (filter === StorageUtilsService.SELECTOR_FILTERS[k].name) {
        this.selectorFilter = StorageUtilsService.SELECTOR_FILTERS[k];

        break;
      }
    }
  }

  isMultipleSelector() {
    return this.storageFull ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILE ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isFileSelector() {
    return this.storageFull ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.SINGLE_FILE ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILE ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isFolderSelector() {
    return this.storageFull ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.SINGLE_FOLDER ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isMultipleFilesFoldersSelector() {
    return this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isFolderFilter() {
    return this.selectorFilter === StorageUtilsService.SELECTOR_FILTERS.FOLDERS;
  }

  canSelect(file) {
    return !this.storageUtilsService.fileIsTrash(file) && !file.isThrottled &&
      (this.storageUtilsService.fileIsFolder(file) && this.isFolderSelector() ||
        !this.storageUtilsService.fileIsFolder(file) && this.isFileSelector()
      ) &&
      !this._isFilteredFile(file);
  }

  isDisabled(file) {
    return this._isFilteredFile(file) ||
      !this.storageUtilsService.fileIsFolder(file) && !this.isFileSelector();
  }

  showAddFolderModal(folderPath: string, existingFiles: any[]): Promise<any> {
    return this.modalService.showMediumDialog(NewFolderModalComponent, {
      folderPath,
      existingFiles,
    }, null, 'more-actions-button');
  }

  showCopyUrlModal(copyFile: any): Promise<any> {
    return this.modalService.showLargeDialog(CopyUrlModalComponent, {
      copyFile,
    }, null, 'more-actions-button');
  }

  showRenameFileModal(selectedFile: any, renameObject: Function): Promise<any> {
    return this.modalService.showMediumModal(RenameFileModalComponent, {
      initialState: {
        selectedFile,
        renameObject,
        returnFocusId: 'more-actions-button'
      }
    });
  }

  showStorageModal(type: string = 'single-file', filter: string = '', enableByURL: boolean = false): Promise<any> {
    return this.modalService.showLargeDialog(StorageModalComponent, {
      selectorType: type,
      selectorFilter: filter,
      enableByURL,
    }, null, 'more-actions-button');
  }

  showFolderSelectorModal(folderPath: string, excludedFiles: string[]): Promise<any> {
    return this.modalService.showMediumModal(FolderSelectorModalComponent, {
      initialState: {
        folderPath: folderPath,
        isTrashFolder: this.storageUtilsService.isTrashFolder(folderPath),
        excludedFiles: excludedFiles,
        returnFocusId: 'more-actions-button'
      }
    });
  }

}

angular.module('risevision.storage.services')
  .factory('storageFactory', downgradeInjectable(StorageService));

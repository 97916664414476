import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { InsecureUrl, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';

import { StateService } from '@uirouter/angular';
import { type ApiListResponse, type ApiResponse } from 'src/app/api/api';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { type Schedule } from 'src/app/api/services/schedule';
import { ScheduleApiService } from 'src/app/api/services/schedule-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  _hasSchedules;
  _scheduleId;
  _hasAppleTvDisplays = false;
  schedule: any;

  loadingSchedule;
  savingSchedule;
  apiError;

  constructor(
    private stateService: StateService,
    private broadcaster: BroadcasterService,
    private processErrorCode: ProcessErrorCode,
    private trackerService: TrackerService,
    private modalService: ModalService,
    private companyStateService: CompanyStateService,
    private insecureUrl: InsecureUrl,
    private scheduleApiService: ScheduleApiService,
    private displayApiService: DisplayApiService
  ) {
    this._init();

    this.broadcaster.on('risevision.company.selectedCompanyChanged', () => {
      this._hasSchedules = undefined;
    });
  }

  _clearMessages () {
    this.loadingSchedule = false;
    this.savingSchedule = false;

    this.apiError = '';
  }

  _init () {
    this._scheduleId = undefined;

    this.schedule = {
      name: 'New Schedule',
      scheduleType: 'default',
      companyId: this.companyStateService.getSelectedCompanyId(),
      content: [],
      distributeToAll: false,
      distributeToSubcompanies: false,
      distribution: [],
      subcompanyDistribution: [],
      timeDefined: false
    };

    this._clearMessages();
  }

  newSchedule (skipTracking?) {
    if (!skipTracking) {
      this.trackerService.scheduleEvent('Add Schedule');
    }

    this._init();
  }

  getSchedule (scheduleId): Promise<void> {
    this._clearMessages();
    //load the schedule based on the url param
    this._scheduleId = scheduleId;

    //show loading spinner
    this.loadingSchedule = true;

    return this.scheduleApiService.get(this._scheduleId)
      .then((result) => {
        this._hasSchedules = true;

        this.schedule = result.item;
      })
      .catch((e) => {
        this._showErrorMessage(e);

        return Promise.reject();
      })
      .finally(() => {
        this.loadingSchedule = false;
      });
  }

  hasSchedules () {
    if (typeof this._hasSchedules === 'undefined') {
      this._hasSchedules = false;

      // Load status in background. Used by Template Editor to enable/disable Publish button for first time users
      // The objective is being able to associate the Auto Schedule Modal with a user action (in this case, Publish)
      this.checkFirstSchedule()
        .then(() => {
          this._hasSchedules = false;
        })
        .catch((err) => {
          if (err !== 'Already have Schedules') {
            console.log('Failed while checking if company has Schedules', err);
          }
        });
    }

    return this._hasSchedules;
  }

  checkFirstSchedule (): Promise<ApiListResponse<any>> {
    if (!this._hasSchedules) {
      return this.scheduleApiService.list({
        count: 1
      })
      .then((result) => {
        if (result && (!result.items || result.items.length === 0)) {
          return;
        } else {
          this._hasSchedules = true;
          return Promise.reject('Already have Schedules');
        }
      });
    } else {
      return Promise.reject('Already have Schedules');
    }
  }

  getAllDisplaysSchedule (): Promise<Schedule | void> {
    return this.scheduleApiService.list({
      filter: 'distributeToAll:true'
    })
    .then((result) => {
      if (result && result.items && result.items.length > 0) {
        return result.items[0];
      }
    })
    .catch(() => {});
  }

  hasFreeDisplays () {
    var distribution = this.schedule.distribution ? this.schedule.distribution : [];

    if (!distribution.length && !this.schedule.distributeToAll) {
      return Promise.resolve([]);
    }

    return this.displayApiService.hasFreeDisplays(this.schedule.companyId,
      this.schedule.distributeToAll ? null : distribution);
  }

  checkAppleTvDisplays = function () {
    var id = this.schedule.id;
    var search = {
      filter: `(scheduleId:=${id} || overrideScheduleIds:=${id}) && playerName:="Apple TV Player"`,
      count: 1,
      sortBy: 'name'
    };

    this._hasAppleTvDisplays = false;

    return this.displayApiService.list(search)
      .then(result => {
        this._hasAppleTvDisplays = !!(result && result.items && result.items.length > 0);
        return this._hasAppleTvDisplays;
      });
  };

  hasAppleTvDisplays () {
    return this._hasAppleTvDisplays;
  }

  addSchedule () {
    this._clearMessages();

    //show loading spinner
    this.loadingSchedule = true;
    this.savingSchedule = true;

    return this._addSchedule()
      .then((resp) => {
        if (resp && resp.item && resp.item.id) {
         this._hasSchedules = true;

          this.broadcaster.emit('scheduleCreated');

          this.trackerService.scheduleEvent('Schedule Created', resp.item.id, resp.item.name, {
            scheduleType: resp.item.scheduleType
          });

          if (this.stateService.current.name === 'apps.schedules.add') {
            this.stateService.go('apps.schedules.details', {
              scheduleId: resp.item.id
            });
          }
        }
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loadingSchedule = false;
        this.savingSchedule = false;
      });
  }

  updateSchedule () {
    this._clearMessages();

    //show loading spinner
    this.loadingSchedule = true;
    this.savingSchedule = true;

    return this._updateSchedule()
      .then((resp) => {
        this.schedule = resp.item;

        this.broadcaster.emit('scheduleUpdated');

        this.trackerService.scheduleEvent('Schedule Updated', this._scheduleId, this.schedule.name, {
          scheduleType: this.schedule.scheduleType
        });
      })
      .catch((e) => {
        this._showErrorMessage(e);

        return Promise.reject();
      })
      .finally(() => {
        this.loadingSchedule = false;
        this.savingSchedule = false;
      });
  }

  forceUpdateSchedule (providedSchedule?): Promise<void> {
    var scheduleToUpdate = providedSchedule || this.schedule;

    this._clearMessages();

    //show loading spinner
    this.loadingSchedule = true;
    this.savingSchedule = true;

    return this.scheduleApiService.update(scheduleToUpdate.id, scheduleToUpdate, true)
      .then((resp) => {
        if (!providedSchedule) {
          this.schedule = resp.item;
        }

        this.trackerService.scheduleEvent('Schedule Updated', scheduleToUpdate.id, scheduleToUpdate.name, {
          scheduleType: scheduleToUpdate.scheduleType
        });
      })
      .catch((e) => {
        this._showErrorMessage(e);

        return Promise.reject();
      })
      .finally(() => {
        this.loadingSchedule = false;
        this.savingSchedule = false;
      });
  }

  deleteScheduleByObject (scheduleObject): Promise<void> {
    return this.scheduleApiService.delete(scheduleObject.id)
      .then(() => {
        this._hasSchedules = undefined;
        this.trackerService.scheduleEvent('Schedule Deleted', scheduleObject.id, scheduleObject.name, {
          scheduleType: scheduleObject.scheduleType
        });
      });
  }

  deleteSchedule () {
    this._clearMessages();

    //show loading spinner
    this.loadingSchedule = true;

    return this.deleteScheduleByObject(this.schedule)
      .then(() => {
        this.schedule = {};

        this.stateService.go('apps.schedules.list');
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loadingSchedule = false;
      });
  }

  addToDistribution (display, schedule) {
    if (!schedule || !schedule.id || schedule.id === display.scheduleId || schedule.distributeToAll) {
      return Promise.resolve();
    }
    return this.addAllToDistribution([display], schedule);
  }

  _addToDistributionList (displayId, schedule) {
    schedule.distribution = schedule.distribution ? schedule.distribution : [];
    if (schedule.distribution.indexOf(displayId) === -1) {
      schedule.distribution.push(displayId);
    }
  }

  addAllToDistribution (displays, scheduleToUpdate) {
    if (!scheduleToUpdate || !scheduleToUpdate.id || scheduleToUpdate.distributeToAll) {
      return Promise.resolve();
    }
    console.info('Adding to Distribution: ', displays, scheduleToUpdate.id);

    _.each(displays, (display) => {
      this._addToDistributionList(display.id, scheduleToUpdate);
    });
    return this.forceUpdateSchedule(scheduleToUpdate)
      .then(() => {
        _.each(displays, (display) => {
          if (scheduleToUpdate.scheduleType === 'default') {
            display.scheduleId = scheduleToUpdate.id;
            display.scheduleName = scheduleToUpdate.name;
          }
        });
      });
  }

  requiresLicense (schedule?) {
    schedule = schedule || this.schedule;

    if (schedule && schedule.content && schedule.content.length > 0) {
      for (var i = 0; i < schedule.content.length; i++) {
        if (schedule.content[i].type === 'presentation') {
          return true;
        }
      }
    }
    return false;
  }

  hasInsecureUrls (schedule?) {
    schedule = schedule || this.schedule;

    if (schedule && schedule.content && schedule.content.length > 0) {
      for (var i = 0; i < schedule.content.length; i++) {
        if (schedule.content[i].type === 'url' && this.insecureUrl(schedule.content[i].objectReference)) {
          return true;
        }
      }
    }
    return false;
  }

  _updateSchedule (): Promise<ApiResponse<any>> {
    return this.scheduleApiService.update(this._scheduleId, this.schedule)
      .catch((err) => {
        if (err.status === 409) {
          return this._showDistributionConflictModal()
            .then(() => {
              return this.scheduleApiService.update(this._scheduleId, this.schedule, true);
            });
        }
        return Promise.reject(err);
      });
  }

  _addSchedule (): Promise<ApiResponse<any>> {
    return this.scheduleApiService.add(this.schedule).catch((err) => {
      if (err.status === 409) {
        return this._showDistributionConflictModal()
          .then(() => {
            return this.scheduleApiService.add(this.schedule, true);
          });
      }
      return Promise.reject(err);
    });
  }

  _showDistributionConflictModal () {
    return this.modalService.confirm('The selected displays already have schedules.',
        'Some of the displays you selected are already assigned to another schedule. Would you like to re-assign them to this schedule?',
        'Yes', 'No', null, { panelClass: ['reassign-distribution-modal'] })
      .catch(() => {
        return Promise.reject({
          message: 'Some of the displays are already assigned to another schedule.'
        });
      });
  }

  _showErrorMessage (e) {
    this.apiError = this.processErrorCode(e);

    console.error(this.apiError, e);
  }

}
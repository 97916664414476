import { Component, TemplateRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';

import { UserStateService } from 'src/app/auth/services/user-state.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';

@Component({
  selector: 'auth-buttons',
  templateUrl: './auth-buttons.component.html',
  styleUrl: './auth-buttons.component.scss'
})
export class AuthButtonsComponent {

  get isScreenShareApp(): any {
    return this.rvShareAppDetection.isScreenShareApp();
  }

  get userPicture() {
    return this.userStateService.getUserPicture();
  }

  get companyName() {
    return this.companyStateService.getUserCompanyName();
  }

  get username() {
    return this.userStateService.getUsername();
  }

  get userFullName() {
    return this.userStateService.getUserFullName();
  }

  get isAssignedPublisher() {
    return this.userStateService.isAssignedPublisher();
  }

  constructor(
    private rvShareAppDetection: RvshareAppDetectionService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    public actionSheet: MatBottomSheet,
  )
  { }

  openMobileMenu(template: TemplateRef<any>) {
    this.actionSheet.open(template, { panelClass: 'auth-buttons-action-sheet' });
  }
}

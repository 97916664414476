import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'widget-item',
  templateUrl: './widget-item.component.html',
  styleUrls: ['./widget-item.component.scss']
})
export class WidgetItemComponent {

  WEBSITE_REGEX = ValidationService.WEBSITE_REGEX;

  constructor(public dialogRef: MatDialogRef<WidgetItemComponent>) { }

  form = {
    url: undefined,
    settingsUrl: undefined
  };

  apply() {
    this.dialogRef.close(this.form);
  }

  dismiss() {
    this.dialogRef.close(false);
  }

}

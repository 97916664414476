<section id="TemplateList1" aria-labelledby="FeaturedHeader" #weeklyTemplatesContainer
  class="xl:!tw-w-[288px] xl:!tw-bg-white tw-bg-gray-100 tw-border-solid tw-border-b-0 tw-border-r-0 tw-border-t-0 tw-border-gray-200 tw-z-50 tw-pb-8 xl:!tw-pb-0">
  <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-block xl:!tw-hidden tw-px-4 tw-pt-12 tw-mb-4">
    <h2 class="tw-text-2xl tw-font-medium tw-m-0 tw-mb-2">Upcoming Events</h2>
    <a class="tw-text-2xl tw-text-green-600 tw-no-underline hover:tw-underline focus:tw-outline focus:tw-outline-2 focus:tw-outline-blue-500"
      href="#" (click)="$event.preventDefault(); editorService.addPresentationModal()" role="button" tabindex="0">See more</a>
  </div>
  <div
    class="xl:!tw-px-12 tw-overflow-y-auto no-scrollbar tw-flex focus:tw-outline focus:tw-outline-2 focus:tw-outline-blue-500 tw-outline-offset-2"
    tabindex="0" role="region" aria-label="Weekly templates">
    @if (!this.weeklyTemplates?.items?.list?.length) {
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full xl:!tw-min-h-[calc(100vh-8rem)]">
      <mat-spinner [diameter]="30" class="tw-mx-auto"></mat-spinner>
      <p class="tw-text-center tw-text-gray-500 tw-mt-4">Loading...</p>
    </div>
    }
    @else {
    <div id="weekly-templates-expanded"
      class="tw-flex xl:!tw-flex-col tw-flex-row tw-min-w-fit xl:!tw-min-w-0 tw-w-max xl:!tw-w-auto tw-h-full">
      <div class="tw-flex xl:!tw-flex-col tw-flex-row tw-gap-2 tw-min-w-fit xl:!tw-min-w-0 tw-w-max xl:!tw-w-auto">
        <div class="tw-pt-8 tw-hidden xl:!tw-block" resizeObserver (resizeEvent)="onCalendarResize($event)">
          <h2 class="tw-text-2xl tw-font-medium tw-m-0 tw-text-center">Upcoming Events</h2>
          <template-calendar class="tw-w-full tw-max-w-[250px]" [displayed]="datesAvailable"
            [(selectedDate)]="selectedDate" (selectedDateChange)="openLibrary()"></template-calendar>
        </div>
        <ul
          class="tw-grid tw-grid-flow-col xl:!tw-grid-flow-row tw-grid-rows-1 xl:!tw-grid-rows-none xl:!tw-grid-cols-1 tw-gap-8 tw-px-4 xl:!tw-px-0 xl:!tw-py-4 xl:!tw-pl-0 tw-m-0 tw-overflow-y-auto no-scrollbar xl:!tw-max-h-[--template-card-height]">
          @for (product of weeklyTemplates.items.list; track product) {
          <li class="tw-cursor-pointer tw-group tw-w-full tw-max-w-[250px]" id="store-product-{{ product.productId }}"
            tabindex="0" role="button" aria-label="Use this template" (click)="select(product)">
            <div class="tw-relative tw-overflow-hidden tw-rounded-lg">
              <img src="{{ product.imageUrl }}" alt="{{ product.name }}"
                class="tw-w-full tw-h-auto tw-border-solid tw-border-1 tw-border-gray-200 tw-rounded-md">
              <div
                class="tw-absolute tw-inset-0 tw-bg-black/50 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                <span class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-text-white">Use this
                  Template</span>
              </div>
            </div>
            <div class="tw-flex tw-gap-2 tw-pt-4 xl:!tw-pt-4 tw-flex-col tw-flex-col-reverse xl:!tw-flex-row">
              <p class="xl:!tw-hidden tw-text-xl tw-font-bold">{{ product.templateCalendarStartDate | date:'MMM':'UTC' }}
                {{ product.templateCalendarStartDate | date:'d':'UTC' }}</p>
              <div
                class="tw-hidden xl:!tw-flex tw-flex-shrink-0 tw-bg-gray-50 tw-h-16 tw-w-16 tw-mr-2 tw-p-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-sm">
                <p class="tw-text-2xl tw-font-bold tw-text-center tw-text-black tw-m-0">{{ product.templateCalendarStartDate |
                  date:'d':'UTC' }}</p>
                <p class="tw-text-base tw-text-center tw-text-gray-600/70 tw-font-bold tw-m-0">{{
                  product.templateCalendarStartDate | date:'MMM':'UTC' | uppercase }}</p>
              </div>
              <p
                class="tw-m-0 tw-text-2xl tw-text-left tw-w-full tw-break-words tw-line-clamp-1 xl:!tw-line-clamp-2 tw-leading-tight">
                {{ product.name }}</p>
            </div>
          </li>
          }
        </ul>
      </div>
    </div>
    }
  </div>
</section>
<div rvSpinner="move-company-modal" [rvShowSpinner]="loading">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" data-dismiss="modal" aria-label="Click to close this modal" (click)="closeModal()">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="10" height="10"></streamline-icon>
    </button>
  </div>
  <h4 id="move-company-label">Move Company</h4>
  <mat-dialog-content class="move-company-modal tw-mt-4">
    <form role="form">
      <div class="form-group">
        <label for="auth-key">
          Enter the Authentication Key of the Company that you want to move.
        </label>
        <div class="tw-flex xs:tw-flex-col md:tw-flex-row tw-gap-4">
          <div class="md:tw-flex-1">
            <input id="auth-key" name="authKeyInput" type="text" class="form-control"
              [(ngModel)]="company.authKey" />
          </div>
          <button mat-stroked-button
            class="retrieve-company-details-button md:tw-flex-1 tw-text-2xl"
            type="submit"
            [disabled]="company.authKey ? null : true"
            (click)="getCompany()">Retrieve Company Details</button>
          <div >
          </div>
        </div>
      </div>
    </form>
    <div [shown]="company.name" class="company-details-info">
      <h5>Move <b>{{company.name}}</b> to <b>{{selectedCompany.name}}</b></h5>
      <div class="flex-row">
        <div class="border-container w-50 p-3">
          {{company.name}}<br>
          {{company.address}}
          {{company.city}}, {{company.province}},
          {{company.country}} {{company.postalCode}}
        </div>
        <div class="mx-2">→</div>
        <div class="to-company border-container w-50 p-3">
          {{selectedCompany.name}}<br>
          {{selectedCompany.address}}
          {{selectedCompany.city}}, {{selectedCompany.province}},
          {{selectedCompany.country}} {{selectedCompany.postalCode}}
        </div>
      </div>
    </div>
    <div [shown]="errors.length > 0">
      @for (error of errors; track error) {
        <div class="alert alert-danger mt-4 mb-1">
          {{error}}
        </div>
      }
    </div>
    <div [shown]="messages.length > 0">
      @for (message of messages; track message) {
        <div class="alert alert-success mt-4 mb-1">
          {{message}}
        </div>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button type="button" mat-stroked-button class="close-move-company-button" data-dismiss="modal" (click)="closeModal()">
      {{dismissButtonText}}
    </button>
    <button type="button"
      mat-flat-button
      color="primary"
      class="move-company-button"
      [shown]="company.name && !moveSuccess" (click)="moveCompany()">
      Move Company
    </button>
  </mat-dialog-actions>
</div>

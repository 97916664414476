<form id="alertForm">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="close()" aria-label="Close this modal">
      <streamline-icon ariaLabel="Close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Failed to delete Presentation.</h4>
  <mat-dialog-content>
    @if (embeddingSchedules.length > 0) {
      <div>
        <p class="tw-text-start">The following schedules have <b>{{presentation.name}}</b> included. Remove it from those schedules and try again.</p>
        @for (embedding of embeddingSchedules; track embedding) {
          <div class="tw-flex tw-justify-center">
            <a uiSref="apps.schedules.details"
              [uiParams]="{ scheduleId: embedding.id, cid: embedding.companyId }"
              class="u_ellipsis-lg" target="_blank">
              <strong>{{embedding.name}}</strong>
            </a>
          </div>
        }
      </div>
    }
    @if (embeddingSchedules.length > 0 && embeddingPresentations.length > 0) {
      <hr>
    }
    @if (embeddingPresentations.length > 0) {
      <div>
        <p class="tw-text-start">The following presentations have <b>{{presentation.name}}</b> included in their playlists. Remove it from those presentations and try again.</p>
        @for (embedding of embeddingPresentations; track embedding) {
          <div class="tw-flex tw-justify-center">
            @if (!isHtmlPresentation(embedding)) {
              <a uiSref="apps.editor.workspace.artboard" [uiParams]="{presentationId: embedding.id}"
                class="u_ellipsis-lg" target="_blank">
                <strong>{{embedding.name}}</strong>
              </a>
            }
            @else {
              <a uiSref="apps.editor.templates.edit" [uiParams]="{presentationId: embedding.id}"
                class="u_ellipsis-lg" target="_blank">
                <strong>{{embedding.name}}</strong>
              </a>
            }
          </div>
        }
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button type="button" mat-flat-button color="primary" class="tw-w-full" (click)="close()">
      <span>Close</span>
    </button>
  </mat-dialog-actions>
</form>

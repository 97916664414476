@if (appleTVDisplays && nonAppleTVPresentations) {
  <div class="madero-style alert alert-warning flex-row align-start" role="alert">
    <i aria-hidden="true" class="fa fa-exclamation-circle icon-left text-warning" style="line-height: inherit"></i>

    <div>
      <strong>Apple TV Compatibility Warning</strong>

      <div>
        One or more playlist items in your schedule will not play on your Apple TV.
      </div>
    </div>
  </div>
}

<timeline-textbox
  [useLocaldate]="true"
  [(timelineObject)]="scheduleService.schedule"
  [readonly]="isAssignedPublisher">
</timeline-textbox>
<distribution-selector
  [(distribution)]="scheduleService.schedule.distribution"
  (distributionChange)="checkLicenseRequired()"
  [(subcompanyDistribution)]="scheduleService.schedule.subcompanyDistribution"
  [(distributeToSubcompanies)]="scheduleService.schedule.distributeToSubcompanies"
  (distributeToSubcompaniesChange)="distributeToSubcompaniesChange()"
  [(distributeToAll)]="scheduleService.schedule.distributeToAll"
  (distributeToAllChange)="checkLicenseRequired()"
  [enableSubcompanies]="true"
  [readonly]="isAssignedPublisher">
</distribution-selector>
<div class="danger mt-2" [shown]="freeDisplays?.length && licenseRequired && !playerLicenseService.updatingLicense && !playerLicenseService.apiError">
  You selected {{freeDisplays?.length}} unlicensed display{{freeDisplays?.length > 1 ? 's' : ''}} that won't show content. <a href="#" class="madero-link" (click)="licenseFreeDisplays()">License {{freeDisplays?.length > 1 ? 'them' : 'it'}}?</a>
</div>
<div class="danger mt-2" [shown]="freeDisplays?.length && !licenseRequired && !playerLicenseService.updatingLicense && !playerLicenseService.apiError">
  You selected {{freeDisplays?.length}} unlicensed display{{freeDisplays?.length > 1 ? 's' : ''}}. <a href="#" class="madero-link" (click)="licenseFreeDisplays()">License {{freeDisplays?.length > 1 ? 'them' : 'it'}}?</a>
</div>
<div class="mt-2" [shown]="playerLicenseService.updatingLicense">
  Assigning licenses <i aria-label="Loading" class="fa fa-spinner fa-spin fa-fw"></i>
</div>
<div class="danger mt-2" [shown]="playerLicenseService.apiError">
  {{playerLicenseService.apiError}}
</div>
<schedule-type-selector
  [(scheduleType)]="scheduleService.schedule.scheduleType"
  (scheduleTypeChange)="scheduleTypeChange()"
  [(playOnce)]="scheduleService.schedule.playOnce"
  [readonly]="isAssignedPublisher">
</schedule-type-selector>
<div class="schedule-fields-body fields-preview-panels mt-4">
  <div class="fields-container">
    <div class="u_padding-20-vertical flex-row">
      <label class="mb-0 mr-auto">Playlist:</label>
      @if (scheduleService.schedule?.content?.length) {
        <div class="btn-group dropdown" dropdown>
          <button id="addPlaylistItemButton" type="button" class="btn btn-default btn-toolbar dropdown-toggle" [matMenuTriggerFor]="playlistMenu">Add Playlist Item</button>
          <mat-menu #playlistMenu="matMenu">
            <button mat-menu-item id="addPresentationItemButton" (click)="addPresentationItem()" (keyup.enter)="addPresentationItem()">Presentation</button>
            <button mat-menu-item id="addUrlItemButton" (click)="addUrlItem()" (keyup.enter)="addUrlItem()">URL</button>
          </mat-menu>
        </div>
      }
    </div>
    <playlist [playlistItems]="scheduleService.schedule.content" [shown]="scheduleService.schedule?.content?.length"></playlist>
    @if (!scheduleService.schedule?.content?.length) {
      <div class="border-container text-center">
        <div class="panel-body">
          <div class="form-group my-4">
            <label>Start by creating playlist items.</label>
          </div>
          <div class="btn-group dropdown mb-2" dropdown>
            <button id="addPlaylistItemButton" type="button" class="btn btn-default btn-toolbar dropdown-toggle" [matMenuTriggerFor]="playlistMenu">Add Playlist Item</button>
            <mat-menu #playlistMenu="matMenu">
              <button mat-menu-item id="addPresentationItemButton" (click)="addPresentationItem()" (keyup.enter)="addPresentationItem()">Presentation</button>
              <button mat-menu-item id="addUrlItemButton" (click)="addUrlItem()" (keyup.enter)="addUrlItem()">URL</button>
            </mat-menu>
          </div>
        </div>
      </div>
    }
  </div>
  <!-- presentationIds field is only updated when the Schedule is saved -->
  @if (scheduleService.schedule.id && scheduleService.schedule?.content?.length) {
    <div class="preview-container">
      <div class="flex-row border-bottom u_padding-20-vertical mb-4">
        <div class="row-entry">
          <label class="pull-left mr-auto mb-0">Schedule Preview:</label>
          <mat-checkbox id="applyTimeline" name="applyTimeline" [(ngModel)]="applyTimeline"></mat-checkbox>
          <span class="mr-3">Apply Timeline to preview.</span>
          <share-schedule-button buttonClass="btn-default btn-toolbar"></share-schedule-button>
        </div>
      </div>
      <div class="align-right mb-4" [shown]="isMobile">
        <button class="btn btn-default btn-toolbar" (click)="isMobile = false">Show Preview</button>
      </div>
      <div class="preview-embed" [hidden]="isMobile">
        <iframe frameborder="0" [src]="isMobile ? '' : sanitizedPreviewUrl">
        </iframe>
      </div>
    </div>
  }
</div>
<div id="insecureUrlsErrorBox" [shown]="scheduleService.hasInsecureUrls() && !scheduleService.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>This schedule’s playlist contains playlist items which can’t show because the URL is insecure. Make sure the URLs begin with "HTTPS". If you need assistance, please email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a>.</strong>
</div>
<div id="errorBox" [shown]="scheduleService.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{scheduleService.apiError}}</strong>
</div>

<div id="select-company" class="tw-flex tw-flex-col tw-h-full" (click)="$event.stopPropagation()">
  <div class="company-menu-header">
    <div class="tw-flex tw-justify-between tw-items-center">
      <h4 id="switch-company" class="tw-font-medium">
        Select Sub-Company
      </h4>
      <button
        type="button"
        id="select-company-close"
        mat-icon-button
        class="close tw-mr-[-10px]"
        aria-label="Close the company menu"
        (click)="closeMenu.emit()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
    @if (companies.apiError) {
      <div id="errorBox" class="alert alert-danger" role="alert">
        <strong>{{companies.errorMessage}}</strong> {{companies.apiError}}
      </div>
    }
    @if (companyStateService.isSubcompanySelected()) {
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <div class="tw-flex tw-items-center tw-gap-6">
          <initials-badge [name]="companyStateService.getUserCompanyName()" [size]="30"></initials-badge>
          <div>
            <div class="tw-text-[11px] tw-font-medium tw-text-gray-500">My Company</div>
            <div class="tw-font-medium">{{companyStateService.getUserCompanyName()}}</div>
          </div>
        </div>
        <button
          id="reset-subcompany-button"
          type="button"
          mat-stroked-button
          (click)="resetCompany()"
          class="tw-font-medium"
        >Switch</button>
      </div>
    }
    <!-- Search -->
    <search-filter class="tw-w-full" [filterConfig]="filterConfig" [search]="search" [doSearch]="companies.doSearch"></search-filter>
    <div class="tw-flex tw-justify-between tw-mt-4">
      <label for="allCompaniesCheckbox" class="font-weight-medium u_nowrap mb-0 u_clickable" (click)="toggleAllCompanies(!allCompanies)">Show all sub-companies</label>
      <mat-slide-toggle id="allCompaniesCheckbox" class="ml-2" [checked]="allCompanies" (change)="toggleAllCompanies($event.checked)"></mat-slide-toggle>
    </div>
  </div>
  <div class="select-subcompany-modal">
    <!-- Scrollable list -->
    <div
      class="tw-border-y-[1px] tw-border-x-0 tw-border-solid tw-border-gray-200"
      scrollingList
      (scrollEndEvent)="companies.load()"
      rvSpinner="select-company-list"
      [rvShowSpinner]="companies.loadingItems"
    >
      <div id="companySelectorListTable">
        @for (company of companies.items.list; track company) {
          <div class="company-list-item" (click)="setCompany(company)">
            <initials-badge [name]="company.name" [size]="30"></initials-badge>
            <span class="list-group-item-text tw-overflow-hidden tw-text-nowrap tw-whitespace-nowrap tw-text-ellipsis tw-font-medium">{{company.name}}</span>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="company-menu-footer">
    <button type="button" (click)="licenseManagement()" id="company-licenses-button">
      <div class="tw-flex tw-gap-4 tw-items-center tw-justify-between tw-w-full">
        <div class="tw-flex tw-gap-4">
          <mat-icon svgIcon="company" class="dark"></mat-icon>
          <span>Companies &amp; Licenses</span>
        </div>
        @if (companyAccessService.accessCompanyLicenses) {
          <mat-icon class="heavy" svgIcon="chevron-right" id="company-licenses-chevron-right"></mat-icon>
        }
        @else {
          <mat-icon class="small" svgIcon="requires-upgrade" id="company-licenses-requires-upgrade"></mat-icon>
        }
      </div>
    </button>
  </div>
</div>

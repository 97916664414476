<form id="copyUrlModal">
  <div class="tw-flex tw-flex-row tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button id="closeButton" class="close" (click)="cancel()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 id="title">Link To "{{ copyFile.name }}"</h4>
  <mat-dialog-content class="tw-mt-4">
    <div class="input-group">
      <input #copyUrlInput
        id="copyUrlInput"
        type="text"
        readonly
        class="form-control readonly-clean"
        value="{{ copyUrl }}"
        (focus)="selectInput()"
        [focusMe]="true"
      >
      <span class="input-group-addon u_clickable" title="Copy URL" (mousedown)="copyToClipboard($event)">
        <streamline-icon ariaLabel="Copy URL" name="copy" width="15" height="15"></streamline-icon>
      </span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="submit" mat-flat-button color="primary" (click)="cancel()">
      Ok
    </button>
  </mat-dialog-actions>

</form>

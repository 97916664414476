import { Injectable } from '@angular/core';

import { Log, UserManager, WebStorageStateStore } from 'oidc-client';

import { LocalStorageHelperService } from 'src/app/shared/services/local-storage-helper.service';
import { UserStateService } from './user-state.service';

@Injectable({
  providedIn: 'root'
})
export class OpenidConnectLoaderService {

  CLIENT_ID = '614513768474.apps.googleusercontent.com';
  OAUTH2_SCOPES = 'email profile';

  userManager: UserManager;

  constructor(
    private localStorageHelperService: LocalStorageHelperService,
    private userStateService: UserStateService
  ) {

    var loc = window.location.origin + '/';

    Log.logger = console;
    Log.level = Log.WARN;

    const settings = {
      authority: 'https://accounts.google.com/',
      client_id: this.CLIENT_ID,
      response_type: 'token id_token',
      scope: this.OAUTH2_SCOPES,
      prompt: 'select_account',
      redirect_uri: loc,
      post_logout_redirect_uri: loc + 'oidc-client-sample.html',

      silent_redirect_uri: loc + 'user-manager-silent.html',
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: false,

      filterProtocolClaims: true,
      loadUserInfo: true,

      userStore: new WebStorageStateStore({
        store: window.localStorage
      }),
      extraQueryParams: {
        access_type: 'online'
      }
    };

    this.userManager = new UserManager(settings);

    var _signinSilent = this.userManager.signinSilent.bind(this.userManager);

    this.userManager.signinSilent = (params) => {
      if (!params) {
        params = {
          login_hint: this.userStateService.getUsername()
        };
      }

      return _signinSilent(params);
    };
  }

  get() {
    if (!this.localStorageHelperService.isSupported) {
      return Promise.reject('Oidc client not found!');
    }

    return Promise.resolve(this.userManager);
  };

}

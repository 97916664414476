import { Component, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'stretchy-input',
  templateUrl: './stretchy-input.component.html',
  styleUrls: ['./stretchy-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StretchyInputComponent),
      multi: true
    }
  ]
})
export class StretchyInputComponent implements OnChanges, ControlValueAccessor {
  public isEditingInput = false;
  public defaultInputValue: string;
  public defaultInputWidth = '';

  @Input() ngModel: string;
  @Output() ngModelChange = new EventEmitter<string>();

  private onChange = (value: string) => {};
  private onTouched = () => {};

  constructor(private elementRef: ElementRef) { }

  _getStretchyElement() {
    return this.elementRef.nativeElement.querySelector('input.input-stretchy');
  }

  _initStretchy() {
    let stretchyInputElement = this._getStretchyElement();

    if (window.Stretchy) {
      window.Stretchy.resize(stretchyInputElement);
    }

    this.defaultInputWidth = window.getComputedStyle(stretchyInputElement).getPropertyValue('width');
  }

  ngOnChanges(changes: SimpleChanges) {
    // resize input if model was externally modified/updated
    if (changes && !this.isEditingInput) {
      this.defaultInputValue = this.ngModel;
      setTimeout(this._initStretchy.bind(this));
    }
  }

  _setFocus(elem: any) {
    if (elem !== null) {
      if (elem.createTextRange) {
        var range = elem.createTextRange();
        range.move('character', 0);
        range.select();
      } else {
        if (elem.setSelectionRange) {
          elem.focus();
          elem.setSelectionRange(0, elem.value.length);
        } else {
          elem.focus();
        }
      }
    }
  }

  setEditable() {
    if (this.isEditingInput) {
      return;
    }

    let stretchyInputElement = this._getStretchyElement();
    setTimeout(this._setFocus.bind(this, stretchyInputElement));
    this.isEditingInput = true;
  }

  _setNonEditable() {
    if (!this.isEditingInput) {
      return;
    }

    let stretchyInputElement = this._getStretchyElement();
    let nameVal = stretchyInputElement.value.replace(/\s/g, '');

    if (!nameVal) {
      // user deleted entire name, set the name and width values to the defaults
      this.ngModel = this.defaultInputValue;
      this.ngModelChange.emit(this.ngModel);
      this.onChange(this.ngModel);

      stretchyInputElement.value = this.ngModel;
      stretchyInputElement.style.width = this.defaultInputWidth;
    } else {
      this.onChange(stretchyInputElement.value);
    }

    this.isEditingInput = false;
    this.onTouched();
  }

  onInputBlur() {
    this._setNonEditable();
  }

  inputKeyDown(keyEvent: KeyboardEvent) {
    // handle enter key
    if (keyEvent.key === 'Enter' && this.isEditingInput) {
      this._setNonEditable();
      keyEvent.preventDefault();
    }
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.ngModel = value;
      this.defaultInputValue = value;
      setTimeout(this._initStretchy.bind(this));
    } else {
      this.ngModel = '';
      this.defaultInputValue = '';
      setTimeout(this._initStretchy.bind(this));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    const input = this._getStretchyElement();
    if (input) {
      input.disabled = isDisabled;
    }
  }
}
<mat-button-toggle-group (valueChange)="toggleListView($event)" name="list-view-toggle" [(ngModel)]="value" [hideSingleSelectionIndicator]="true">
  <mat-button-toggle id="gridViewSelector"
    title="List view"
    value="list"
    aria-label="Toggle list view">
    <mat-icon svgIcon="list-view" />
  </mat-button-toggle>
  <mat-button-toggle
    id="listViewSelector"
    title="Grid view"
    value="grid"
    aria-label="Toggle grid view">
    <mat-icon svgIcon="grid-view" />
  </mat-button-toggle>
</mat-button-toggle-group>
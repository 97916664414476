<div id="productDetailsModal">
  <div class="tw-flex tw-items-center tw-mr-2 tw-mt-2">
    <h4 class="tw-flex-1 tw-text-center">{{product.name}}</h4>
    <button type="button" mat-icon-button class="close" (click)="dismiss()" aria-label="Close this modal">
      <streamline-icon ariaLabel="Close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <mat-dialog-content class="tw-flex md:tw-flex-row xs:tw-flex-col tw-p-8">
    <div class="md:tw-w-8/12 xs:tw-w-full">
      <img class="img-responsive" [src]="product.imageUrl">
      <p class="tw-mt-5" [innerHtml]="product.descriptionShort"></p>
      <p>
        @if (showPreviewLink) {
          <a id="previewTemplate" class="madero-link" href="http://widgets.risevision.com/viewer/?type=presentation&id={{product.rvaEntityId}}&showui=false" target="_blank">Preview this Template</a>
        }
      </p>
    </div>
    <div class="md:tw-w-4/12 xs:tw-w-full">
      <button id="useProductButton" class="tw-w-full tw-text-2xl" mat-flat-button color="primary" (click)="select()">
        Start with this Template
      </button>
    </div>
  </mat-dialog-content>
</div>

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';
import { FilesListComponent } from '../files-list/files-list.component';

@Component({
  selector: 'storage-modal',
  templateUrl: './storage-modal.component.html',
  styleUrls: ['./storage-modal.component.scss']
})
export class StorageModalComponent implements OnInit {

  selectorType: string;
  selectorFilter: string = '';
  enableByURL: boolean = false;

  selectedItemsCount: number = 0;

  @ViewChild(FilesListComponent) filesList: FilesListComponent;

  get title() {
    return this.storageUtilsService.storageTitle(this.selectorType || 'single-file', this.selectorFilter);
  }

  constructor (
    public dialogRef: MatDialogRef<StorageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StorageModalData,
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService
  ) {
    this.selectorType = data.selectorType;
    this.selectorFilter = data.selectorFilter;
  }

  ngOnInit(): void {
    this.storageService.setSelectorType(this.selectorType, this.selectorFilter);
    this.storageService.storageFull = false;
  }

  selectByUrl () {
    // send blank response to indicate By Url selection
    this.dialogRef.close('url');
    this.storageService.reset();
  }

  close (files: any[]) {
    this.dialogRef.close(files);
    this.storageService.reset();
  }

  dismiss () {
    this.dialogRef.close(false);
    this.storageService.reset();
  }
}

interface StorageModalData {
  selectorType: string;
  selectorFilter: string;
}
<ng-template #authButtonsMenu>
  <div class="tw-px-8 tw-pt-6 tw-pb-2 tw-flex tw-items-center tw-gap-4">
    @if (userPicture) {
      <img [src]="userPicture" class="profile-pic" width="40" height="40" alt="User" />
    } @else {
      <initials-badge [name]="userFullName" [size]="40"></initials-badge>
    }
    <div>
      <div class="tw-font-bold tw-text-gray-700">{{ userFullName }}</div>
      <div class="tw-text-[13px] tw-text-gray-500">{{ username }}</div>
    </div>
  </div>
  <company-buttons></company-buttons>

  <mat-nav-list id="authButtonsMenu">
    @if (!isScreenShareApp) {
      <a mat-list-item uiSref="apps.user.details" [uiParams]="{username:''}" class="user-settings-button action">
        <streamline-icon aria-hidden="true" name="user" width="14" height="14"></streamline-icon>
        <span class="item-name pl-2">User Settings</span>
      </a>
      @if (!isAssignedPublisher) {
        <a mat-list-item uiSref="apps.billing.home" class="store-account-button action">
          <streamline-icon aria-hidden="true" name="cart" width="14" height="14"></streamline-icon>
          <span class="item-name pl-2">Account & Billing</span>
        </a>
      }
    }
    @else {
      <a mat-list-item href="https://help.risevision.com/hc/en-us/articles/29009023259156-How-do-I-Set-Up-Screen-Sharing-on-a-display-in-Rise-Vision-Coming-Soon"
        target="_blank" class="action">
        <streamline-icon aria-hidden="true" name="help" width="14" height="14"></streamline-icon>
        <span class="item-name pl-2">Help</span>
      </a>
    }
    <a mat-list-item href="https://help.risevision.com/hc/en-us/articles/360000924446-Terms-of-Service" target="_blank" class="action">
      <streamline-icon aria-hidden="true" name="terms" width="14" height="14"></streamline-icon>
      <span class="item-name pl-2">Software Terms of Service</span>
    </a>
  </mat-nav-list>
  <div class="dropdown-footer text-right">
    <button mat-stroked-button color="primary" class="u_margin-sm-bottom mt-3 sign-out-button" sign-out-button>Sign Out</button>
  </div>
</ng-template>

@if (isScreenShareApp) {
  <div class="dropdown user-profile-dropdown">
    <a href="#" class="dropdown-toggle" (click)="actionSheet.open(authButtonsMenu)">
      <mat-icon svgIcon="menu" class="large dark"></mat-icon>
    </a>
  </div>
}
@else {
  <!-- Desktop and tablet -->
  <div class="dropdown user-profile-dropdown desktop-menu-item hidden-xs">
    <button mat-icon-button
      type="button"
      [matMenuTriggerFor]="menuAuthButtons"
      class="dropdown-toggle hover:tw-bg-brand-bg"
      aria-label="User Menu">
      @if (userPicture) {
        <img [src]="userPicture" class="profile-pic" width="32" height="32" alt="User" />
      } @else {
        <initials-badge class="profile-pic" [name]="userFullName" [size]="32"></initials-badge>
      }
    </button>
    <mat-menu #menuAuthButtons="matMenu" class="auth-buttons-menu">
      <ng-template matMenuContent>
        <ng-container *ngTemplateOutlet="authButtonsMenu"></ng-container>
      </ng-template>
    </mat-menu>

  </div>
  <!-- Mobile -->
  <div class="dropdown user-profile-dropdown mobile-menu-item visible-xs-inline-block">
    <button mat-icon-button type="button" class="visible-xs dropdown-toggle" (click)="openMobileMenu(authButtonsMenu)"
      action-sheet-class="user-profile-dropdown madero-style" aria-label="User Menu">
      <img [src]="userPicture" class="profile-pic" width="32" height="32" alt="User" />
    </button>
  </div>
}

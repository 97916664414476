import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GoogleService } from './google.service';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService extends GoogleService {
  public static readonly ENDPOINT_GROUPS = 'groups';
  public static readonly ENDPOINT_DASHBOARDS = 'dashboards';
  public static readonly ENDPOINT_REPORTS = 'reports';

  async getGroups(account) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account
    }});

    const request = new HttpRequest('GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_GROUPS, options
    );

    try {
      const response: any = await lastValueFrom(this.httpClient.request(request));
      if (response?.body?.groups) {
        return response.body.groups;
      }
      throw new Error('Invalid powerbi groups data');
    } catch (err) {
      console.error('Failed to get powerbi groups.', err);
      throw err;
    }
  }

  async getDashboards(account, groupId) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      groupId: groupId === 'my-workspace' ? '' : groupId
    }});

    const request = new HttpRequest('GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_DASHBOARDS, options
    );

    try {
      const response: any = await lastValueFrom(this.httpClient.request(request));
      if (response?.body?.dashboards) {
        return response.body.dashboards;
      }
      throw new Error('Invalid powerbi dashboards data');
    } catch (err) {
      console.error('Failed to get powerbi dashboards.', err);
      throw err;
    }
  }

  async getReports(account, groupId) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      groupId: groupId === 'my-workspace' ? '' : groupId
    }});

    const request = new HttpRequest('GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_REPORTS, options
    );

    try {
      const response: any = await lastValueFrom(this.httpClient.request(request));
      if (response?.body?.reports) {
        return response.body.reports;
      }
      throw new Error('Invalid powerbi reports data');
    } catch (err) {
      console.error('Failed to get powerbi reports.', err);
      throw err;
    }
  }
}

<div class="flex-row">

  <input type="text" class="input-stretchy" (keydown)="inputKeyDown($event)" name="stretchyInput"
    [(ngModel)]="ngModel" (ngModelChange)="ngModelChange.emit($event)" (blur)="onInputBlur()" [disabled]="!isEditingInput">
  <div>
    <button tabindex="0" id="editButton" aria-label="Set name editable" [disabled]="isEditingInput" type="button" class="btn-icon ml-3" (click)="setEditable()">
      <streamline-icon ariaLabel="Set name editable" class="streamline-component-icon" name="pencil" width="16" height="16"></streamline-icon>
    </button>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PresentationUtilsService } from '../../services/presentation-utils.service';

@Component({
  selector: 'product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  product;
  showPreviewLink = false;

  constructor(public dialogRef: MatDialogRef<ProductDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data: { product: any },
    private presentationUtils: PresentationUtilsService) {
      this.product = data.product;
  }

  ngOnInit() {
    this.showPreviewLink = !this.presentationUtils.isHtmlTemplate(this.product);
  }

  select() {
    this.dialogRef.close(this.product);
  }

  dismiss() {
    this.dialogRef.close(false);
  }

}

<div class="tw-pt-8" rvSpinner="display-loader" [rvShowSpinner]="displayService.loadingDisplay">
  <form
    #displayForm="ngForm"
    role="form"
    id="forms.displayForm"
    name="displayDetails"
    (submit)="save()"
    novalidate>
    <display-fields
      [(selectedSchedule)]="selectedSchedule"
      [(displayControlType)]="displayControlType"
    />
    <div class="button-row mt-5 text-right">
      <p class="visible-sm visible-xs text-left"><last-modified [changeDate]="displayService.display.changeDate" [changedBy]="displayService.display.changedBy"></last-modified></p>
      <span class="hidden-sm hidden-xs"><last-modified [changeDate]="displayService.display.changeDate" [changedBy]="displayService.display.changedBy"></last-modified></span>
      <!-- Indicates cancel or non-destructive action -->
      <button id="cancelButton" type="button" uiSref="apps.displays.list" class="btn btn-default btn-toolbar">
        Cancel
      </button>
      <!-- Indicates a successful or positive action -->
      <button id="saveButton" type="submit" class="btn btn-primary btn-toolbar" [disabled]="displayForm.invalid || displayService.savingDisplay" *requireRole="'da'">
        {{ displayService.savingDisplay ? 'Saving' : 'Save'}}
      </button>
    </div>
  </form><!-- displayDetails -->
</div><!--container-->
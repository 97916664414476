import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'assign-users-modal',
  templateUrl: './assign-users-modal.component.html',
  styleUrls: ['./assign-users-modal.component.scss']
})
export class AssignUsersModalComponent {

  filterConfig = {
    placeholder: 'Search Users'
  };

  search = {
    companyId: this.companyStateService.getSelectedCompanyId(),
    sortBy: 'username',
    filter: 'roles:~\"ap\"',
    reverse: false,
    name: 'Users',
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };
  users = this.ScrollingListService(this.userApiService.list.bind(this.userApiService), this.search);
  selectedUsers = [];

  constructor(public dialogRef: MatDialogRef<AssignUsersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectedUsers: string[] },
    private companyStateService: CompanyStateService,
    private ScrollingListService: ScrollingListService,
    private userApiService: UserApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.selectedUsers = data.selectedUsers;
  }

  toggleUser (userId) {
    const selected = this.selectedUsers.indexOf(userId);
    if (selected < 0) {
      this.selectedUsers.push(userId);
    } else {
      this.selectedUsers.splice(selected, 1);
    }
  }

  userSelected (userId) {
    return this.selectedUsers.indexOf(userId) >= 0;
  }

  dismiss () {
    this.dialogRef.close(false);
  }

  apply () {
    this.dialogRef.close(this.selectedUsers);
  }

}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'canva-type-picker',
  templateUrl: './canva-type-picker.component.html',
  styleUrls: ['./canva-type-picker.component.scss']
})
export class CanvaTypePickerComponent {

  designType = 'Presentation';

  constructor(public dialogRef: MatDialogRef<CanvaTypePickerComponent>) {
  }

}

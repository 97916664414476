<div id="workspace" class="workspace madero-style" [ngClass]="{'hide-sidebar' : artboardService.hideSidebar()}">

  <toolbar class="workspace-toolbar" id="workspaceToolbar"></toolbar>

  <div class="workspace-wrapper">
    <sidebar class="workspace-sidebar attribute-editor" [ngClass]="{'show-ph-properties' : placeholderService.placeholder}"></sidebar>
    <div class="workspace-board" workspace-element>
      <div ui-view id="editingModeView"></div>
    </div><!--editor-bg-->
  </div><!--workspace-wrapper-->

  <footer class="text-right editor-footer"></footer>

</div>
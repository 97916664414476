<!-- Step 0 -->
<div class="checkout-plan-picker">
  <h3 class="tw-font-bold tw-text-5xl tw-mt-0 tw-mb-8 tw-text-center">Simple, transparent pricing.</h3>
  <h4 class="mb-5 tw-text-center tw-font-normal">Digital signage doesn't have to be difficult. We make it easy or your money back. 30 days risk-free.</h4>
  <form #planPickerForm="ngForm" (ngSubmit)="updatePlan()" id="form.planPickerForm" role="form" name="form.planPickerForm" (keydown.enter)="$event.preventDefault()" novalidate >
    <select-plan
      [(isUnlimitedPlan)]="isUnlimitedPlan"
      (isVolumePlan)="isVolumePlan = $event"
      [(periodMonthly)]="periodMonthly"
      [(displayCount)]="displayCount"
      [(unlimitedCount)]="unlimitedCount"
      (totalPriceChange)="updateTotalPrice($event)"
      (selectedProductCodeChange)="updateProductCode($event)">
    </select-plan>
    <div class="button-toolbar flex-row w-100 w-lg-75 mx-0 mx-lg-auto mb-5">
      <a id="contactSalesButton" href="https://www.risevision.com/contact-us?form_selected=sales&content_hide=true" target="_blank" class="btn btn-default btn-block btn-hg">Contact Sales</a>
      <button type="submit" id="subscribeButton" class="btn btn-primary btn-block btn-hg mt-0" [disabled]="planPickerForm.invalid">Subscribe</button>
    </div>
  </form>
  <div class="w-100 mx-0 madero-style">
    <!-- if industry is 'K-12 Education' -->
    <div [shown]="canAccessUnlimitedPlan">
      <div class="testimonial">
        <div class="border-container">
          <p>
            <b>I use Rise Vision to display announcements throughout my school.</b> I love the templates and … it
            is also very easy to learn the basics of operation. The price is great and we can easily add displays
            as we bring more online.
          </p>
          <img src="/images/checkout/bennington-middle-school-logo.png?width=64&amp;height=64&amp;name=bennington-middle-school-logo.png" alt="Konni In Den Bosch, Bennington Public Schools" width="64" height="64" srcset="/images/checkout/bennington-middle-school-logo.png?width=32&amp;height=32&amp;name=bennington-middle-school-logo.png 32w, /images/checkout/bennington-middle-school-logo.png?width=64&amp;height=64&amp;name=bennington-middle-school-logo.png 64w, /images/checkout/bennington-middle-school-logo.png?width=96&amp;height=96&amp;name=bennington-middle-school-logo.png 96w, /images/checkout/bennington-middle-school-logo.png?width=128&amp;height=128&amp;name=bennington-middle-school-logo.png 128w, /images/checkout/bennington-middle-school-logo.png?width=160&amp;height=160&amp;name=bennington-middle-school-logo.png 160w, /images/checkout/bennington-middle-school-logo.png?width=192&amp;height=192&amp;name=bennington-middle-school-logo.png 192w" sizes="(max-width: 64px) 100vw, 64px">
          <span>Konni In Den Bosch, Bennington Public Schools</span>
        </div>
      </div>
    </div>
    <!-- if industry is not 'K-12 Education' -->
    <div [shown]="!canAccessUnlimitedPlan">
      <div class="testimonial">
        <div class="border-container">
          <p>
            <b>We were able to save about $2000 per year in the budget for our town using Rise Vision.</b> Other
            digital signage services we looked at can cost upward of $1500.00 per location.
          </p>
          <img src="/images/checkout/critial-concepts-web-logo.png?width=64&amp;height=64&amp;name=critial-concepts-web-logo.png" alt="Joe Hannay, Critical Concepts Web" width="64" height="64" srcset="/images/checkout/critial-concepts-web-logo.png?width=32&amp;height=32&amp;name=critial-concepts-web-logo.png 32w, /images/checkout/critial-concepts-web-logo.png?width=64&amp;height=64&amp;name=critial-concepts-web-logo.png 64w, /images/checkout/critial-concepts-web-logo.png?width=96&amp;height=96&amp;name=critial-concepts-web-logo.png 96w, /images/checkout/critial-concepts-web-logo.png?width=128&amp;height=128&amp;name=critial-concepts-web-logo.png 128w, /images/checkout/critial-concepts-web-logo.png?width=160&amp;height=160&amp;name=critial-concepts-web.png 160w, /images/checkout/critial-concepts-web.png?width=192&amp;height=192&amp;name=critial-concepts-web.png 192w" sizes="(max-width: 64px) 100vw, 64px">
          <span>Joe Hannay, Critical Concepts Web</span>
        </div>
      </div>
    </div>
  </div>
  <!-- if industry is 'K-12 Education' -->
  <div class="hidden-xs" [shown]="false">
    <div class="k12-schools-content mt-5">
      <h3 class="font-weight-bold mb-5">Unlimited License For K-12 Schools</h3>
      <ul class="mb-2">
        <li><streamline-icon aria-hidden="true" name="checkmark" class="checkmark"></streamline-icon>Save thousands and get unlimited displays to improve communication through your entire school and district</li>
        <li><streamline-icon aria-hidden="true" name="checkmark" class="checkmark"></streamline-icon>Add digital signage to your hallways, libraries, cafeterias, gyms, auditoriums, offices, lobbies, and more</li>
        <li><streamline-icon aria-hidden="true" name="checkmark" class="checkmark"></streamline-icon>Expand your digital signage to interactive displays and wireless presentations systems in your classrooms
          like Airtame, Mersive, ScreenBeam, Vivi, SMART, Promethean, ViewSonic, New Line, and more!</li>
        <li><streamline-icon aria-hidden="true" name="checkmark" class="checkmark"></streamline-icon>Use existing hardware with our web, Chrome OS, Android, Windows, Linux, Raspberry Pi, and Amazon Fire
          Stick players</li>
        <li><streamline-icon aria-hidden="true" name="checkmark" class="checkmark"></streamline-icon>Simplify your budgeting and add displays anytime without issuing new PO’s or waiting until your budget
          refreshes</li>
        <li><streamline-icon aria-hidden="true" name="checkmark" class="checkmark"></streamline-icon>Our most popular option for schools looking to improve communication and create a positive school culture.</li>
      </ul>
      <p>Rise Vision education licenses can also be purchased through your preferred IT reseller. <br>
        If you want to purchase a mix of unlimited licenses and display licenses for your school district please <a href="https://www.risevision.com/contact-us?form_selected=sales&amp;content_hide=true">contact us</a>.
      </p>
    </div>
  </div>
  <!-- if industry is not 'K-12 Education' -->
  <div class="hidden-xs" [shown]="!canAccessUnlimitedPlan">
    <div class="organizations text-center mt-5">
      <h3 class="font-weight-bold mb-5">
        Trusted by 9000+ organizations, including:
      </h3>
      <div class="logos mb-4">
        <div class="logo">
            <img data-src="/images/sign-up/marriott-logo.png" data-srcset="/images/sign-up/marriott-logo.png" alt="marriott-logo" width="96" height="75" src="/images/sign-up/marriott-logo.png" srcset="/images/sign-up/marriott-logo.png">
        </div>
        <div class="logo">
            <img data-src="/images/checkout/chicago-public-schools-logo.png" data-srcset="/images/checkout/chicago-public-schools-logo.png" alt="chicago-public-schools-logo" width="162" height="75" src="/images/checkout/chicago-public-schools-logo.png" srcset="/images/checkout/chicago-public-schools-logo.png">
        </div>
        <div class="logo">
            <img data-src="/images/checkout/montana-state-university-logo.png" data-srcset="/images/checkout/montana-state-university-logo.png" alt="montana-state-university-logo" width="108" height="75" src="/images/checkout/montana-state-university-logo.png" srcset="/images/checkout/montana-state-university-logo.png">
        </div>
        <div class="logo">
            <img data-src="/images/sign-up/eagles-logo.png" data-srcset="/images/sign-up/eagles-logo.png" alt="philadelphia-eagles-logo" width="149" height="75" src="/images/sign-up/eagles-logo.png" srcset="/images/sign-up/eagles-logo.png">
        </div>
        <div class="logo">
            <img data-src="/images/checkout/go-daddy-logo.png" data-srcset="/images/checkout/go-daddy-logo.png" alt="go-daddy-logo" width="270" height="75" src="/images/checkout/go-daddy-logo.png" srcset="/images/checkout/go-daddy-logo.png">
        </div>
      </div>
    </div>
  </div>
</div>

<form id="companyIdModal" (ngSubmit)="apply()">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button id="dismissButton" mat-icon-button type="button" class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">{{ title || 'Enter Company ID' }}</h4>
  <mat-dialog-content>
    <p class="mb-4">{{ description || 'Apply action to the following Company ID.' }}</p>
    <div class="mb-4">
      <p class="mb-2"><strong>Company ID:</strong></p>
      <input type="text" class="w-100 form-control" [(ngModel)]="companyId" id="inputCompanyId" name="inputCompanyId" [focusMe]="true">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button type="button" mat-stroked-button id="companyIdCancelButton" (click)="dismiss()">
      <span>{{ cancelButton || 'Cancel' }}</span>
    </button>
    <button type="submit" mat-flat-button color="primary" id="companyIdApplyButton" [disabled]="!companyId">
      <span>{{ applyButton || 'Apply' }}</span>
    </button>
  </mat-dialog-actions>
</form>
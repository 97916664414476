<div [ngClass]="classes" [class.placeholder]="isPlaceholder">
  <div class="image-container"
    [class.tw-bg-gray-50]="isPlaceholder"
    [class.folder]="isFolder">
    @if (isVideo) {
      <video preload="metadata">
        <source [src]="imgSrc" type="video/mp4">
      </video>
    } @else {
      <img loading="lazy" alt="Image thumbnail" [src]="imgSrc"/>
    }
  </div>
  @if (!small) {
    <div class="list-item-label tw-flex tw-flex-col" [class.tw-justify-center]="isFolder">
      <span class="tw-font-semibold tw-overflow-hidden tw-text-ellipsis">
        {{file.name | storageFileName:folderPath}}
      </span>
      @if (!isFolder) {
        <span class="tw-text-xl tw-text-gray-600">
          {{ file.timeCreated.value | date:'dd-MMM-yyyy' }} - {{ file.size | storageFileSize }}
        </span>
      }
    </div>
  }
</div>
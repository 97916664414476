<div class="tw-flex tw-flex-col tw-items-center tw-w-full tw-mb-20">
  @if (search.query === undefined) {
    <img src="../images/empty-storage.svg">
    <h3>Store your media files</h3>
    <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
      The files you upload and use in your presentation will be stored here. You can upload images, videos, and documents like word and powerpoint.
    </p>
    <button id="uploadButtonEmptyStateUnsubscribed" mat-button color="primary"
      (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
      Upload Files
    </button>
    <button id="uploadButtonEmptyState" mat-button color="primary"
      (click)="uploadFiles.emit()" [hidden]="!currentPlanService.isPlanActive()">
      Upload Files
    </button>
  } @else {
    <img src="../images/no-results.svg">
    <h3>No results found</h3>
    <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
      No items match your search criteria. Try to search by another keyword. </p>
    <button mat-button color="primary" (click)="resetSearch.emit()">
      Clear results
    </button>
  }
</div>

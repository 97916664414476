<div class="upgrade-modal-container">
  <div class="upgrade-modal-panel">
    <div class="upgrade-modal-text">
      <div class="tw-flex tw-flex-row tw-mb-12">
        <streamline-icon aria-hidden="true" name="circleArrowUp" width="16" height="16" class="rise-blue"></streamline-icon>
        <p class="tw-font-bold tw-mb-4 tw-ml-8">{{ cta }}</p>
      </div>
      <h3 class="tw-font-bold">{{ title }}</h3>
      <p>{{ description }}</p>
      <div class="button-row">
        <a mat-button target="_blank" [href]="helpLink">Learn More</a>
        <button mat-flat-button color="primary" (click)="upgrade()">Upgrade Plan</button>
      </div>
    </div>
  </div>
  <div class="upgrade-modal-panel upgrade-feature-graphic" [ngClass]="background">
    @if (imageSource) {
      <div class="upgrade-feature-image">
        <img [src]="imageSource" alt="source">
      </div>
    }
    <button type="button" mat-icon-button class="close text-white tw-mr-2" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
</div>

<form id="confirmForm">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" [mat-dialog-close]="false" data-dismiss="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4>Almost there!</h4>
  <mat-dialog-content>
    <p class="text-left mb-3 mt-3">What type of image do you want to design?</p>

    <select name="type" [(ngModel)]="designType" class="form-control">
      <option value="Announcement">Announcement</option>
      <option value="BirthdayCard">Birthday Card</option>
      <option value="Calendar">Calendar</option>
      <option value="Flyer">Flyer</option>
      <option value="Infographic">Infographic</option>
      <option value="Label">Label</option>
      <option value="LargeRectangleAd">Large Rectangle Ad</option>
      <option value="LessonPlan">Lesson Plan</option>
      <option value="Logo">Logo</option>
      <option value="MediumRectangleAd">Medium Rectangle Ad</option>
      <option value="Menu">Menu</option>
      <option value="Poster">Poster</option>
      <option value="Presentation">Presentation (16:9)</option>
      <option value="Presentation43">Presentation (4:3)</option>
    </select>

  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button id="confirm-primary" class="btn btn-primary btn-block" [mat-dialog-close]="designType">
      <span>Design with Canva</span>
    </button>
  </mat-dialog-actions>
</form>

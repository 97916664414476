<form rvSpinner="add-to-schedule-spinner" [rvShowSpinner]="scheduleSelectorService.loadingSchedules">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" [mat-dialog-close]="false" data-dismiss="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4>Almost there!</h4>
  <mat-dialog-content>
    <p class="text-left mb-3 mt-3">Do you want to select or add a new schedule for this presentation?</p>

    <schedule-selector additionalTooltipClass="tooltip-sm max-z-index"></schedule-selector>

  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <button id="confirm-primary" mat-flat-button color="primary" [mat-dialog-close]="true">
      <span>Add</span>
    </button>
  </mat-dialog-actions>
</form>

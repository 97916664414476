import { Injectable } from '@angular/core';

import { PlaceholderService } from './placeholder.service';
import { WidgetUtilsService } from './widget-utils.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { GadgetService } from './gadget.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { WidgetSettingsComponent } from '../components/widget-settings/widget-settings.component';

@Injectable({
  providedIn: 'root'
})
export class WidgetModalService {

  RPC_PARAMS = 'up_id=iframeId&parent=parentUrl&';
  WIDGET_PARAMS = 'up_rsW=width&up_rsH=height&up_companyId=cid';

  constructor(
    private companyStateService: CompanyStateService,
    private modalService: ModalService,
    private widgetUtilsService: WidgetUtilsService,
    private placeholderService: PlaceholderService,
    private gadgetService: GadgetService
  ) { }

  _getUrlParams(widgetUrl) {
    var res = '';
    var queryParamsStartPos = widgetUrl.indexOf('?');
    if (queryParamsStartPos === -1) {
      queryParamsStartPos = widgetUrl.indexOf('&');
    }

    if (queryParamsStartPos > 0) {
      res = widgetUrl.substring(queryParamsStartPos);
    }

    return res;
  }

  _getWidgetParams() {
    return this.WIDGET_PARAMS
      .replace('cid', this.companyStateService.getSelectedCompanyId())
      .replace('width', this.placeholderService.placeholder.width)
      .replace('height', this.placeholderService.placeholder.height);
  }

  _getRpcParams() {
    return this.RPC_PARAMS
      .replace('iframeId', 'widget-modal-frame')
      .replace('parentUrl', encodeURIComponent(location.href));
  }

  _getIFrameUrl(widgetUrl, url) {
    var params = this._getUrlParams(widgetUrl);
    url = url
      .replace('http://', '//')
      .replace('https://', '//');

    url += params;
    url += url.indexOf('?') !== -1 || url.indexOf('&') !== -1 ?
      '&' : '?';
    url += this._getRpcParams();
    url += this._getWidgetParams();

    return url;
  }

  _widgetSettingsModal(widget: any) {
    return this.modalService.showModal(WidgetSettingsComponent, {
      class: 'modal-full modal-lg',
      initialState: {
        widget
      }
    });
  }

  _showWidgetIFrameModal(item) {
    if (!item || !item.objectReference && !item.settingsUrl) {
      return;
    }

    if (item.settingsUrl) {
      return this._widgetSettingsModal({
        url: this._getIFrameUrl(item.objectData, item.settingsUrl),
        additionalParams: item.additionalParams
      });
    } else {
      return this.gadgetService.getGadgetById(item.objectReference)
        .then((gadget) => {
          if (!item.objectData) {
            item.objectData = gadget.url;
          }

          return this._widgetSettingsModal({
            url: this._getIFrameUrl(item.objectData, gadget.uiUrl),
            additionalParams: item.additionalParams
          });
        });
    }

  }

  _getWidgetHtmlUrl(url) {
    var res = '';

    if (url) {
      var queryParamsStartPos = url.indexOf('?');

      if (queryParamsStartPos > 0) {
        res = url.substring(0, queryParamsStartPos);
      } else if (queryParamsStartPos === -1 && url.indexOf('&') === -1) {
        res = url;
      }
      // if queryParamsStartPos is 0, return blank url
    }

    return res;
  }

  _updateItemObjectData(item, params) {
    if (params && item.objectData) {
      if (this._getWidgetHtmlUrl(params)) {
        item.objectData = params;
        return;
      }

      item.objectData = item.objectData.split(/[?#]/)[0];
      if (params.charAt(0) === '&') {
        params = params.replace('&', '?');
      }
      if (params.charAt(0) !== '?') {
        params = '?' + params;
      }
      item.objectData += params;
    }
  }

  _updateItemName(item, widgetData) {
    if (item.objectReference === this.widgetUtilsService.getWidgetId('image') ||
      item.objectReference === this.widgetUtilsService.getWidgetId('video')) {
      try {
        var oldAdditionalParams = JSON.parse(item.additionalParams);
        var newAdditionalParams = JSON.parse(widgetData.additionalParams);
        var oldFilename = this.widgetUtilsService.getFileName(oldAdditionalParams.selector
          .storageName);
        var newFilename = this.widgetUtilsService.getFileName(newAdditionalParams.selector
          .storageName);
        if (item.name === oldFilename && newFilename !== '') {
          item.name = newFilename;
        }
      } catch (err) {
        console.debug('Error updating item name:', item.name);
      }
    }
  }

  showSettingsModal(item?) {
    if (item && this.widgetUtilsService.getInAppSettings(item.objectReference)) {
      this.modalService.showMessage('Deprecated Content',
        'This Widget has been deprecated and is no longer available.');

      return Promise.reject('Invalid Playlist Item');
    } else if (item && item.type === 'widget') {
      return this._showWidgetIFrameModal(item)
        .then((widgetData) => {
        if (widgetData) {
          this._updateItemObjectData(item, widgetData.params);

          this._updateItemName(item, widgetData);

          item.additionalParams = widgetData.additionalParams;

          console.info('Widget saved:', widgetData);
        }
      });
    } else {
      return Promise.reject('Invalid Playlist Item');
    }

  }

}

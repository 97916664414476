import { Component, Inject, OnInit } from '@angular/core';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WidgetUtilsService } from '../../services/widget-utils.service';

@Component({
  selector: 'store-content',
  templateUrl: './store-content.component.html',
  styleUrls: ['./store-content.component.scss']
})
export class StoreContentComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<StoreContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { addWidgetByUrl: () => void },
    private widgetUtils: WidgetUtilsService) {
      this.addWidgetByUrl = data.addWidgetByUrl;
  }

  _addWidgetByUrl = null;

  // Provided by users of the component
  addWidgetByUrl: () => void = null;

  products;

  ngOnInit(): void {
    this.products = this.widgetUtils.getProfessionalWidgets();
    if (this.addWidgetByUrl) {
      this._addWidgetByUrl = function () {
        this.close();
        this.addWidgetByUrl();
      };
    }
  }

  slugify(value) {
    return value.toLowerCase().replaceAll(' ', '-');
  }

  select(product) {
    this.dialogRef.close(product);
  }

  close() {
    this.dialogRef.close(false);
  }
}

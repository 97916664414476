import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PresentationsService } from '../services/presentations.service';

@Directive({
  selector: '[presentationName]'
})
export class PresentationNameDirective implements OnChanges {

  @Input() presentationName: string;

  constructor (
    private element: ElementRef,
    private presentationsService: PresentationsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['presentationName']?.currentValue) {
      this.updatePresentationName(changes['presentationName'].currentValue);
    }
  }

  private updatePresentationName(presentationName: string): void {
    this.presentationsService.getPresentationCached(presentationName).then((presentation) => {
      this.element.nativeElement.innerText = presentation.name;
    });
  }
}

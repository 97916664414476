import { Injectable } from '@angular/core';

import { GapiLoader } from 'src/app/ajs-upgraded-providers';

import { UserStateService } from './user-state.service';
import { OpenidConnectService } from './openid-connect.service';
import { UIRouterGlobals } from '@uirouter/angular';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {

  constructor(
    private uiRouterGlobals: UIRouterGlobals,
    private gapiLoader: GapiLoader,
    private userStateService: UserStateService,
    private openIdConnectService: OpenidConnectService
  ) { }

  _setToken(user) {
    var token = {
      access_token: user.access_token,
      expires_in: '3600',
      token_type: 'Bearer'
    };

    this.userStateService.setAccessToken(token);

    return this.gapiLoader().then((gApi) => {
      gApi.client.setToken(token);
    });
  }

  _getUserProfile(currentUser) {
    if (!currentUser) {
      return null;
    }

    const profile = currentUser.profile;
    const email = Array.isArray(profile.email) ? profile.email[0] : profile.email;
    const picture = profile.picture && Array.isArray(profile.picture) ? profile.picture[0] : profile.picture;

    const user = {
      id: profile.sub,
      email,
      picture
    };

    return user;
  }

  /*
   * Responsible for triggering the Google OAuth process.
   *
   */
  authenticate() {
    return this.openIdConnectService.getUser()
      .then((user) => {
        if (user) {
          // Silent means we actually perform the check with API
          if (user.expires_in < 60) {
            return this.openIdConnectService.signinSilent(user.profile.sub);
          } else {
            return user;
          }
        } else if (this.userStateService._state.userToken) {
          return this.openIdConnectService.signinSilent(this.userStateService._state.userToken.id);
        } else {
          return Promise.reject('No user');
        }
      })
      .then((user) => {
        this._setToken(user);

        return this._getUserProfile(user);
      });
  }

  _isPopupAuth() {
    return window.self !== window.top;
  }

  forceAuthenticate(isSignUp?) {
    if (this._isPopupAuth()) {
      return this.openIdConnectService.signinPopup();
    } else {
      var redirectState = this.uiRouterGlobals.params.state;

      this.userStateService._state.redirectState = redirectState;
      this.userStateService._state.isSignUp = !!isSignUp;
      this.userStateService._persistState();

      return this.openIdConnectService.signinRedirect(redirectState);
    }
  }

  signOut() {
    return this.openIdConnectService.removeUser();
  }

}

import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { ModalService } from 'src/app/components/modals/modal.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { AssignUsersModalComponent } from '../components/assign-users-modal/assign-users-modal.component';
import { ActionsMenu } from 'src/app/components/common-actions/common-actions.component';
import { StateService } from '@uirouter/core';
import { ScheduleService } from './schedule.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleActionsService {

  constructor(
    private modalService: ModalService,
    private featuresService: FeaturesService,
    private scheduleService: ScheduleService,
    private trackerService: TrackerService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private stateService: StateService
  ) { }

  getActions(): ActionsMenu {
    return {
      cancelAction: {
        id: 'cancelButton',
        label: 'Cancel',
        action: () => this.stateService.go('apps.schedules.list')
      },
      moreOptions: [
        {
          id: 'assignUsersButton',
          label: 'Assign Users',
          disabled: !this.assignUsersVisible,
          action: () => this.assignUsers()
        }
      ]
    };
  }

  assignUsersVisible(): boolean {
    return this.userStateService.hasRole('cp') &&
      (this.companyStateService.isK12Customer() || this.companyStateService.isHigherEdCustomer());
  }

  assignUsersToSchedule (selectedUsers: string[]) {
    let isFeatureAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_TEACHER_ROLE);

    if (!isFeatureAvailable) {
      this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_TEACHER_ROLE);

      return Promise.reject();
    }

    return this.modalService.showMediumDialog(AssignUsersModalComponent, {
      selectedUsers
    }, { height: '95%' });
  }

  assignUsers() {
    this.assignUsersToSchedule(
      this.scheduleService.schedule && this.scheduleService.schedule.assignedUsers ? _.cloneDeep(this.scheduleService.schedule.assignedUsers) : []
    ).then((users) => {
      if (!_.isEqual(this.scheduleService.schedule.assignedUsers, users)) {
        this.scheduleService.schedule.assignedUsers = users;
        this.trackerService.scheduleEvent('Schedule Assigned Users Changed', this.scheduleService.schedule.scheduleId, this.scheduleService.schedule.scheduleName, {
          assignedUsers: users.length
        });
      }
    }).catch(() => {});
  }
}
<div class="flex-col gap-4 w-100">
  <div class="flex-row gap-3 align-start">
    <div>
      <mat-icon class="svg-icon" aria-hidden="true" svgIcon="user"></mat-icon>
    </div>
    <div class="row-entry flex-col align-start gap-4">
      <div class="flex-row space-between w-100 align-start">
        <div class="flex-col flex-grow">
          <div class="flex-row space-between">
            <label for="standardModeToggle" class="font-weight-bold mb-0">Standard Mode</label>
            <ng-container [ngTemplateOutlet]="licenseRequired"></ng-container>
          </div>
          <span class="u_block text-muted info-text mt-2">Allow one person to share by entering the Pairing Code.</span>
        </div>
        @if (!isLicenseRequired) {
          <mat-slide-toggle
            [(ngModel)]="model.screenSharingStandard"
            (change)="screenShareModeChange('standard', $event)"
            name="screenSharingStandard"
            id="standardModeToggle"></mat-slide-toggle>
        }
      </div>
      @if (model.screenSharingStandard && model.isDisplayControlEnabled) {
        <div class=" madero-style alert alert-info">
          <i aria-hidden="true" class="fa fa-exclamation-circle icon-left rise-blue"></i>
          <strong>Display Control is turned on</strong>
          <p class="mt-2">The display will turn off if there are no scheduled playlists, and the pairing code will not be shown. To allow the display to stay on, go to the <strong>Manage</strong> tab, and turn off <strong>Display Control</strong>.</p>
        </div>
      }
      @if (!isLicenseRequired && model.screenSharingStandard) {
        <div class="flex-row space-between w-100">
          <div class="row-entry space-between align-start">
            <label
              class="font-weight-normal"
              for="requirePinConfirmationToggle">
              Require Pin Confirmation
            </label>
            <mat-slide-toggle
              [(ngModel)]="model.settings.screenSharingPinRequired"
              [disabled]="!model.screenSharingStandard"
              name="requirePinConfirmationToggle"
              #screenSharingEnabled="ngModel"
              ngDefaultControl
              id="requirePinConfirmationToggle">
            </mat-slide-toggle>
          </div>
        </div>
        @if (model.screenSharingStandard && model.settings.screenSharingPinRequired) {
          <div class="flex-col w-100" [ngClass]="{ 'has-error' : screenSharingPin.invalid && (screenSharingPin.dirty || screenSharingPin.touched) }">
            <label for="screenSharingPin">PIN Code</label>
            <input
              #screenSharingPin="ngModel"
              id="screenSharingPin"
              name="screenSharingPin"
              type="tel"
              class="form-control"
              [required]="model.settings.screenSharingPinRequired"
              minlength="4"
              maxlength="4"
              pattern="[0-9]*"
              inputmode="numeric"
              autocomplete="off"
              aria-label="PIN 4-digit PIN Code"
              [(ngModel)]="model.settings.screenSharingPin"
            />
          @if (screenSharingPin.invalid && (screenSharingPin.dirty || screenSharingPin.touched)) {
            <p class="help-block mb-0 mt-1">Please enter a 4-digit numeric PIN code.</p>
          }
        </div>
        }
      }
    </div>
  </div>
  <div class="flex-row gap-3 align-start">
    <div>
      <mat-icon class="svg-icon large" svgIcon="users"></mat-icon>
    </div>
    <div class="row-entry flex-col align-start gap-4">
      <div class="flex-row space-between w-100 align-start">
        <div class="flex-col flex-grow">
          <div class="flex-row space-between">
            <label
              for="moderatorModeToggle"
              class="font-weight-bold mb-0">
              Moderator Mode
            </label>
            <ng-container [ngTemplateOutlet]="licenseRequired"></ng-container>
          </div>
          <span class="u_block text-muted info-text mt-2">Allow multiple participants to join a session by entering the pairing code. <span hidden>They will not be able to join using Airplay.</span></span>
        </div>
        @if(!isLicenseRequired) {
          <mat-slide-toggle
            (change)="screenShareModeChange('moderated', $event)"
            [(ngModel)]="model.screenSharingModerated"
            name="moderatorModeToggle"
            id="moderatorModeToggle"></mat-slide-toggle>
        }
      </div>
    </div>
  </div>
</div>

<div class="madero-style" id="presentationItemModal">
  <form #presentationItemForm="ngForm" id="presentationItemForm" role="form" name="forms.presentationItemForm" novalidate>
    <div class="tw-flex tw-justify-end tw-mr-2">
      <button type="button" mat-icon-button class="close" (click)="dismiss()" aria-label="Click to close this modal">
        <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
      </button>
    </div>
    <h4>Embedded Presentation Settings</h4>
    <mat-dialog-content>
      <div class="tw-w-full align-center">
        <button id="selectPresentationButton" (click)="showPresentationId = false; selectPresentation()" type="button" class="btn btn-default" [ngClass]="{'active' : !showPresentationId}">
          Select Presentation
        </button>
        <span class="u_padding-sm-horizontal text-muted">or</span>
        <button id="enterPresentationIdButton" (click)="showPresentationId = true" type="button" class="btn btn-default" [ngClass]="{'active' : showPresentationId}">
          Enter Presentation ID
        </button>
      </div><!--col-->
      <div class="form-group u_margin-sm-top">
        <label>Presentation:</label>
        <div class="input-group u_margin-sm-bottom" [shown]="!showPresentationId">
          <input id="presentationName" name="presentationName" type="text" class="form-control" [ngModel]="presentationName" readonly="readonly" />
          <span class="input-group-addon" aria-label="Click to clear selection" (click)="clearSelection()">
            <i aria-label="Click to clear selection" class="fa fa-times"></i>
          </span>
        </div>
        <input #presentationId="ngModel" id="presentationId" name="presentationId" type="text" class="form-control u_margin-sm-bottom" [shown]="showPresentationId" [(ngModel)]="_item.objectData" (ngModelChange)="validatePresentationId()" required pattern="^[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}$">
        <a class="madero-link" [shown]="!apiWarning && !presentationItemForm.invalid" uiSref="apps.editor.workspace.artboard" [uiParams]="{presentationId: _item.objectData}" target="_blank">
          Edit Presentation
        </a>
        @if (showPresentationId) {
          <p id="requiredValidation" class="text-danger" [shown]="!presentationId.pristine && presentationId.errors?.['required']">
            Please enter a Presentation ID
          </p>
          <p id="invalidGuidValidation" class="text-danger" [shown]="!presentationId.pristine && presentationId.errors?.['pattern']">
            Presentation ID is not valid. Please enter a valid ID.
          </p>
        }
        @else {
          <p id="selectorValidation" class="text-danger" [shown]="presentationItemForm.invalid">
            No Presentation selected. Please select a Presentation or enter an ID.
          </p>
        }
        @if (showPresentationId) {
          <p id="htmlTemplateValidation" class="text-danger" [shown]="!presentationId.pristine && presentationId.errors?.['template']">
            This Presentation cannot be embedded.
          </p>
        }
        <p class="text-warning" [shown]="apiWarning">
          There was an error accessing the Presentation. This could indicate it does not exist or you cannot access it.
        </p>
      </div><!--form-group-->
    </mat-dialog-content><!--modal-body-->
    <mat-dialog-actions align="end" class="tw-gap-4">
      <button type="button" mat-stroked-button (click)="dismiss()">Cancel</button>
      <button id="presentationItemSave" type="submit" mat-flat-button color="primary" (click)="save()" [disabled]="presentationItemForm.invalid">{{isNew ? 'Add' : 'Apply'}}</button>
    </mat-dialog-actions>
  </form>
</div>

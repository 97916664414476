import { Injectable } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { cloneDeep } from 'lodash';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { EditorService } from 'src/app/editor/services/editor.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { PlaylistItemComponent } from '../components/playlist-item/playlist-item.component';
import { StoreContentComponent } from '../components/store-content/store-content.component';
import { WidgetItemComponent } from '../components/widget-item/widget-item.component';
import { GadgetService } from './gadget.service';
import { PlaceholderPlaylistService } from './placeholder-playlist.service';
import { SettingsService } from './settings.service';
import { WidgetUtilsService } from './widget-utils.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistItemService {
  private readonly IMAGE_ADDITIONAL_PARAMS = {
    selector: {
      selection: undefined,
      storageName: undefined,
      url: undefined
    },
    storage: {
      companyId: undefined,
      fileName: undefined,
      folder: undefined
    },
    resume: true,
    scaleToFit: true,
    position: 'middle-center',
    duration: 10,
    pause: 10,
    autoHide: false,
    url: '',
    background: {}
  };
  private readonly VIDEO_ADDITIONAL_PARAMS = {
    selector: {
      selection: undefined,
      storageName: undefined,
      url: undefined
    },
    url: '',
    storage: {
      companyId: undefined,
      fileName: undefined,
      folder: undefined
    },
    video: {
      scaleToFit: true,
      volume: 50,
      controls: true,
      autoplay: true,
      resume: true,
      pause: 5
    }
  };

  constructor(
    private editorService: EditorService,
    private gadgetService: GadgetService,
    private modalService: ModalService,
    private placeholderPlaylistService: PlaceholderPlaylistService,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService,
    private trackerService: TrackerService,
    private companyStateService: CompanyStateService,
    private widgetUtilsService: WidgetUtilsService
  ) { }

  addContent() {
    this.trackerService.presentationEvent(
      'Add Content',
      this.editorService.presentation.id,
      this.editorService.presentation.name);

    return this.addStoreContent(this.addWidgetByUrl)
      .then(product => product && this._addProduct(product));
  }

  addWidgetByUrl() {
    this.trackerService.presentationEvent(
      'Add Widget By URL',
      this.editorService.presentation.id,
      this.editorService.presentation.name);

    return this.modalService.showMediumDialog(WidgetItemComponent)
      .then((result) => {
        if (result && result.url) {
          this._newWidgetByUrl(result);
        }
      });
  }

  edit(item) {
    return this.modalService.showMediumDialog(PlaylistItemComponent, {
      item
    });
  }

  addStoreContent(addWidgetByUrl: any) {
    return this.modalService.showLargeDialog(StoreContentComponent, {
      ...(addWidgetByUrl ? { addWidgetByUrl: addWidgetByUrl.bind(this) } : {})
    });
  }

  addTextWidget() {
    this.trackerService.presentationEvent(
      'Add Text Widget',
      this.editorService.presentation.id,
      this.editorService.presentation.name);

    this._getItemByWidgetId(this.widgetUtilsService.getWidgetId('text'))
      .then((item) => {
        this.settingsService.showSettingsModal(item);
      });
  }

  selectFiles(type) {
    this.storageService.showStorageModal(StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS, type, true)
      .then((fileObjects) => {
        if (fileObjects) {
          var files = this.storageUtilsService.getFileUrls(fileObjects);
          for (var i = 0; i < files.length; i++) {
            if (type === 'images') {
              this._addImage(files[i], fileObjects[i])
                .then((item) => {
                  this._updatePlaylistItem(item);
                });
            } else if (type === 'videos') {
              this._addVideo(files[i], fileObjects[i])
                .then((item) => {
                  this._updatePlaylistItem(item);
                });
            }
          }
        } else {
          if (type === 'images') {
            this._addImage(null, null).then((item) => {
              this.settingsService.showSettingsModal(item);
            });
          } else if (type === 'videos') {
            this._addVideo(null, null).then((item) => {
              this.settingsService.showSettingsModal(item);
            });
          }
        }
      });
  }

  private _getItemByWidgetId(widgetId) {
    return this.gadgetService.getGadgetById(widgetId)
      .then((gadget) => {
        return (this._newWidget(gadget));
      });
  }

  private _getFolder(file) {
    if (!file) {
      return '';
    }

    var index = file.lastIndexOf('/');
    if (index === -1) {
      return '';
    } else {
      return file.substr(0, index + 1);
    }
  }

  private _populateAdditionalParams(additionalParams, fileUrl, file) {
    additionalParams = cloneDeep(additionalParams);

    if (fileUrl && file) {
      additionalParams.selector.storageName = file.name;
      additionalParams.selector.url = fileUrl;
      additionalParams.storage.companyId = this.companyStateService.getSelectedCompanyId();
      if (file.kind === 'folder') {
        additionalParams.selector.selection = StorageUtilsService.SELECTOR_TYPES.SINGLE_FOLDER;
        additionalParams.storage.folder = file.name;
      } else {
        additionalParams.selector.selection = StorageUtilsService.SELECTOR_TYPES.SINGLE_FILE;
        additionalParams.storage.fileName = this.widgetUtilsService.getFileName(file.name);
        additionalParams.storage.folder = this._getFolder(file.name);
      }
    } else {
      additionalParams.selector.selection = 'custom';
      additionalParams.storage = {};
    }

    return JSON.stringify(additionalParams);
  }

  private _addImage(fileUrl, file) {
    this.trackerService.presentationEvent(
      'Add Image Widget',
      this.editorService.presentation.id,
      this.editorService.presentation.name);

    return this._getItemByWidgetId(this.widgetUtilsService.getWidgetId('image'))
      .then((item) => {
        item.name = file ? this.widgetUtilsService.getFileName(file.name) : item.name;

        item.additionalParams = this._populateAdditionalParams(this.IMAGE_ADDITIONAL_PARAMS, fileUrl, file);

        return item;
      });
  }

  private _addVideo(fileUrl, file) {
    this.trackerService.presentationEvent(
      'Add Video Widget',
      this.editorService.presentation.id,
      this.editorService.presentation.name);

    return this._getItemByWidgetId(this.widgetUtilsService.getWidgetId('video'))
      .then((item) => {
        item.name = file ? this.widgetUtilsService.getFileName(file.name) : item.name;
        item.playUntilDone = true;

        item.additionalParams = this._populateAdditionalParams(this.VIDEO_ADDITIONAL_PARAMS, fileUrl, file);

        return item;
      });
  }

  private _updatePlaylistItem(item) {
    this.placeholderPlaylistService.updateItem(item);
  }

  private _newWidgetByUrl(widgetDetails) {
    var item = this._newPlaylistItem();
    item.type = 'widget';
    item.name = 'Widget from URL';
    item.objectData = widgetDetails.url;
    if (widgetDetails.settingsUrl) {
      item.settingsUrl = widgetDetails.settingsUrl;
    }
    this.edit(item);
  }

  private _newPlaylistItem(): any {
    return {
      duration: 10,
      distributeToAll: true,
      timeDefined: false,
      additionalParams: null
    };
  }

  private _newWidget(widget) {
    var item = this._newPlaylistItem();

    item.type = widget.gadgetType ? widget.gadgetType.toLowerCase() : 'widget';
    item.name = widget.name ? widget.name : 'Widget Item';

    if (item.type !== 'presentation') {
      item.objectData = widget.url;
      item.objectReference = widget.id;
      item.settingsUrl = widget.settingsUrl;
    }

    return item;
  }

  private _addProduct(productDetails) {
    this.trackerService.presentationEvent(
      'Content Selected',
      this.editorService.presentation.id,
      this.editorService.presentation.name);

    var promise;

    if (productDetails.productCode) {
      promise = this.gadgetService.getGadgetByProduct(productDetails.productCode);
    } else {
      promise = Promise.resolve(productDetails);
    }

    return promise
      .then((gadget) => {
        var item = this._newWidget(gadget);

        if (item.type === 'widget' || item.type === 'presentation') {
          this.settingsService.showSettingsModal(item);
        } else {
          this.edit(item);
        }
      });
  }
}

angular.module('risevision.editor.services')
  .factory('playlistItemService', downgradeInjectable(PlaylistItemService));

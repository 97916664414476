<form id="confirmForm">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" [mat-dialog-close]="false" data-close="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4>{{ planExpired ? 'Subscription Required' : 'Plan Upgrade Required' }}</h4>
  <mat-dialog-content>
    <div class="plan-features">
      @if (!planExpired) {
        <p>
          This feature is not available on your current plan{{ currentPlan ? ' (' + currentPlan + ')' : '' }}.
          @if (!parentPlan) {
            <span>
              Upgrade <span [shown]="!!needsPlan">to <strong>{{ needsPlan }}</strong></span> in order to access this feature.
            </span>
          }
        </p>
      }
      @else {
        <p>
          This feature requires an active plan.
          @if (!parentPlan) {
            <span>
            Subscribe <span [shown]="!!needsPlan">to <strong>{{ needsPlan }}</strong></span> in order to access this feature.
          </span>
        }
      </p>
      }
      @if (parentPlan) {
        <p>
          Contact your parent company to update your subscription.
        </p>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    @if (!parentPlan) {
      <div class="tw-flex-1">
        <button type="button" mat-stroked-button class="tw-w-full" [mat-dialog-close]="false">
          <span>Cancel</span>
        </button>
      </div>
      <div class="tw-flex-1">
        <button type="button" mat-flat-button color="primary" id="confirm-primary" class="tw-w-full" [mat-dialog-close]="true">
          <span>{{ planExpired ? 'Subscribe' : 'Upgrade' }}</span>
        </button>
      </div>
    }
    @else {
      <div class="tw-flex-1">
        <button type="button" mat-flat-button color="primary" class="tw-w-full" [mat-dialog-close]="false">
          <span>Okay, I Got It</span>
        </button>
      </div>
    }
  </mat-dialog-actions>
</form>

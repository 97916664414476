<div class="add-subcompany tw-pt-8" rvSpinner="add-subcompany" [rvShowSpinner]="loading">
  <form #companyForm="ngForm" id="forms.companyForm" role="form" name="forms.companyForm" (submit)="save()" autocomplete="on" novalidate>
    <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
      <strong>{{formError}}</strong> {{apiError}}
      @if (isAddressError) {
        <suggest-general-delivery [addressObject]="company"></suggest-general-delivery>
      }
    </div>
    <company-fields [company]="company"></company-fields>
    <div class="form-group">
      <a mat-button color="accent" data-dismiss="modal" data-toggle="modal" id="moveButton" class="madero-link"
        (click)="moveCompany()">Move a Company Under Your Company</a>
    </div>
    <div class="button-row text-right mt-5">
      <!-- Indicates cancel or non-destructive action -->
      <button id="cancelButton" type="button" (click)="cancel()" class="btn btn-default btn-toolbar mr-2">
        Cancel
      </button>
      <!-- Indicates a successful or positive action -->
      <button id="saveButton" form="forms.companyForm" type="submit" class="btn btn-primary btn-toolbar" [disabled]="companyForm.invalid" *requireRole="'ua sa'">
        Save
      </button>
    </div>
  </form><!-- companyAdd -->
</div>
<div class="checkout-plan-picker tw-pt-8">
  <form (ngSubmit)="updatePlan()" id="form.changePlanForm" role="form" name="form.changePlanForm" novalidate>
    <select-plan
      [(isUnlimitedPlan)]="isUnlimitedPlan"
      [(periodMonthly)]="periodMonthly"
      [displayCount]="displayCount"
      [planType]="planType"
      [subscriptionId]="subscriptionId"
      (selectedProductCodeChange)="updateProductCode($event)">
    </select-plan>

    <div class="button-toolbar flex-row w-100 w-lg-75 mx-0 mx-lg-auto mb-5">
      <a id="cancelButton" href="#" (click)="cancel()" class="btn btn-default btn-block btn-hg">Cancel</a>
      <button type="submit" id="subscribeButton" class="btn btn-primary btn-block btn-hg mt-0">Next</button>
    </div>
  </form>

</div>

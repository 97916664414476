<div id="newFolderModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" (click)="cancel()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-text-center">Create Folder</h4>
  <mat-dialog-content>
    <div class="form-group">
      <label for="newFolderInput">Folder Name:</label>
      <input id="newFolderInput" type="text" class="form-control" [(ngModel)]="folderName" placeholder="Enter Folder Name" (focus)="duplicateFolderSpecified=false" [focusMe]="true">
      <br>
      <div [shown]="duplicateFolderSpecified">
        <div class="alert alert-danger" role="alert">A Folder already uses that name, please choose a different one.</div>
      </div>
      <div [shown]="accessDenied && accessDeniedMessage">
        <div class="alert alert-danger" role="alert">{{accessDeniedMessage}}</div>
      </div>
      <div [shown]="accessDenied && !accessDeniedMessage">
        <div class="alert alert-danger" role="alert">You don’t have permission to do this.</div>
      </div>
      <div [shown]="serverError">
        <div class="alert alert-danger" role="alert">An error has occured. {{respCode}}</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button id="newFolderModalCancelButton" type="button" mat-stroked-button (click)="cancel()">
        Cancel
      </button>
      <button id="newFolderModalSaveButton" type="submit" mat-flat-button color="primary" (click)="ok()" [disabled]="!folderName || accessDenied || serverError || waitingForResponse">
        Save
      </button>
  </mat-dialog-actions>
</div>

<div rvSpinner="social-media-loader" [rvShowSpinner]="spinner">
  <div id="mainView" [shown]="!currentView" class="social-media-posts-container scrollable-component-container">
    <div class="attribute-editor-component" >
      <div class="scrollable-component-settings te-scrollable-container">

        @if(authenticateFailed || revokeFailed) {
          <div class="attribute-editor-row">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group has-error">
                  <p class="help-block">
                    <span [shown] = "authenticateFailed">Sorry, it seems there's an issue with authentication.</span>
                    <span [shown] = "revokeFailed">We could not revoke your access with {{revokeFailedProviderName}} at this time. We strongly recommend you manually revoke access in your social media account.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }

        <div class="feed-selector-container">
          <div class="attribute-editor-row">
            <div class="row form-group">
              <div class="col-xs-6">
                <label class="control-label u_margin-sm-top">Social Feeds:</label>
              </div>
              <div class="col-xs-6 text-right">
                <div class="provider-connected-file-info"  [hidden]="feeds.size === FEEDS_COUNT">
                  <button class="add-feed-button" mat-stroked-button [matMenuTriggerFor]="addFeedMenu">
                    Add Feed <mat-icon svgIcon="plus" iconPositionEnd></mat-icon>
                  </button>
                  <mat-menu #addFeedMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="addFeed(FeedType.FB_PAGE)" [hidden]="feeds.has(FeedType.FB_PAGE)">
                      <mat-icon svgIcon="facebook" class="large"></mat-icon>
                      <span>Facebook Page</span>
                    </button>
                    <button mat-menu-item (click)="addFeed(FeedType.FB_USER)" [hidden]="feeds.has(FeedType.FB_USER)">
                      <mat-icon svgIcon="facebook" class="large"></mat-icon>
                      <span>Facebook Profile</span>
                    </button>
                    <button mat-menu-item (click)="addFeed(FeedType.IG_PAGE)" [hidden]="feeds.has(FeedType.IG_PAGE)">
                      <mat-icon svgIcon="instagram" class="large"></mat-icon>
                      <span>Instagram</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>

          @if(feeds.has(FeedType.FB_PAGE)) {
            <div class="attribute-editor-row">
              <div class="row form-group">
                <div class="col-xs-12">
                  <div class="provider-connected-container">
                    <div class="provider-connected-file-info">
                      <div class="file-details">
                        <mat-icon aria-hidden="true" svgIcon="facebook" class="icon-left large"></mat-icon>
                        <div class="file-text">
                          <span class="file-title">{{feeds.get(FeedType.FB_PAGE).pageName}}</span>
                          <span class="file-item-count">Facebook - Page</span>
                        </div>
                      </div>
                      <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="fbPageOptions">
                        <i class="fa fa-chevron-down file-options-icon"></i>
                      </button>
                      <mat-menu #fbPageOptions="matMenu" xPosition="before">
                        <button mat-menu-item (click)="removeFeed(FeedType.FB_PAGE)">
                          <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
                          <span>Remove Feed</span>
                        </button>
                        @if(feeds.get(FeedType.FB_PAGE).userAccount === userAccount) {
                          <hr class="m-2">
                          <button mat-menu-item (click)="confirmDisconnect(FeedType.FB_PAGE)">
                            <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                            <span>Disconnect Account</span>
                          </button>
                        }
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 has-error" [shown] = "authStatusFailed.has(FeedType.FB_PAGE)">
                  <p class="help-block">
                    <span>
                      There is an issue with your authentication. Please click on "Disconnect Account" and then add your feed again.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          }

          @if(feeds.has(FeedType.FB_USER)) {
            <div class="attribute-editor-row">
              <div class="row form-group">
                <div class="col-xs-12">
                  <div class="provider-connected-container">
                    <div class="provider-connected-file-info">
                      <div class="file-details">
                        <mat-icon aria-hidden="true" svgIcon="facebook" class="icon-left large"></mat-icon>
                        <div class="file-text">
                          <span class="file-title">{{feeds.get(FeedType.FB_USER).userDisplayName}}</span>
                          <span class="file-item-count">Facebook - Profile</span>
                        </div>
                      </div>
                      <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="fbPageOptions">
                        <i class="fa fa-chevron-down file-options-icon"></i>
                      </button>
                      <mat-menu #fbPageOptions="matMenu" xPosition="before">
                        <button mat-menu-item (click)="removeFeed(FeedType.FB_USER)">
                          <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
                          <span>Remove Feed</span>
                        </button>
                        @if(feeds.get(FeedType.FB_USER).userAccount === userAccount) {
                          <hr class="m-2">
                          <button mat-menu-item (click)="confirmDisconnect(FeedType.FB_USER)">
                            <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                            <span>Disconnect Account</span>
                          </button>
                        }
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 has-error" [shown] = "authStatusFailed.has(FeedType.FB_USER)">
                  <p class="help-block">
                    <span>
                      There is an issue with your authentication. Please click on "Disconnect Account" and then add your feed again.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          }

          @if(feeds.has(FeedType.IG_PAGE)) {
            <div class="attribute-editor-row">
              <div class="row form-group">
                <div class="col-xs-12">
                  <div class="provider-connected-container">
                    <div class="provider-connected-file-info">
                      <div class="file-details">
                        <mat-icon aria-hidden="true" svgIcon="instagram" class="icon-left large"></mat-icon>
                        <div class="file-text">
                          <span class="file-title">{{feeds.get(FeedType.IG_PAGE).userDisplayName}}</span>
                          <span class="file-item-count">Instagram</span>
                        </div>
                      </div>
                      <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="igPageOptions">
                        <i class="fa fa-chevron-down file-options-icon"></i>
                      </button>
                      <mat-menu #igPageOptions="matMenu" xPosition="before">
                        <button mat-menu-item (click)="removeFeed(FeedType.IG_PAGE)">
                          <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
                          <span>Remove Feed</span>
                        </button>
                        @if(feeds.get(FeedType.IG_PAGE).userAccount === userAccount) {
                          <hr class="m-2">
                          <button mat-menu-item (click)="confirmDisconnect(FeedType.IG_PAGE)">
                            <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                            <span>Disconnect Account</span>
                          </button>
                        }
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 has-error" [shown] = "authStatusFailed.has(FeedType.IG_PAGE)">
                  <p class="help-block">
                    <span>
                      There is an issue with your authentication. Please click on "Disconnect Account" and then add your feed again.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          }
        </div>

        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <label class="control-label" id="layout-selection-label">Choose Layout:</label>
                <div class="layout-container" role="radiogroup" aria-labelledby="layout-selection-label">
                  <button type="button"
                          class="layout-option"
                          [ngClass]="{'layout-option--selected': layout === LayoutType.WATERFALL}"
                          (click)="onLayoutSelect(LayoutType.WATERFALL)"
                          [attr.aria-pressed]="layout === LayoutType.WATERFALL"
                          role="radio">
                    <div class="layout-icon-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none" aria-hidden="true" role="img">
                        <rect x="23.3994" y="0.350098" width="19.5165" height="22.4809" rx="1" fill="#020620" fill-opacity="0.3"/>
                        <rect x="23.3994" y="26.1309" width="19.5165" height="40.2182" rx="1" fill="#020620" fill-opacity="0.3"/>
                        <rect x="0.15918" y="0.350098" width="19.5165" height="35.9808" rx="1" fill="#020620" fill-opacity="0.3"/>
                        <rect x="0.15918" y="40.0317" width="19.5165" height="26.3174" rx="1" fill="#020620" fill-opacity="0.3"/>
                        <rect x="46.6426" y="0.350098" width="19.5165" height="27.8434" rx="1" fill="#020620" fill-opacity="0.3"/>
                        <rect x="46.6426" y="31.4932" width="19.5165" height="34.8558" rx="1" fill="#020620" fill-opacity="0.3"/>
                      </svg>
                    </div>
                    <div class="layout-label">Waterfall</div>
                  </button>
                  <button type="button"
                          class="layout-option"
                          [ngClass]="{'layout-option--selected': layout === LayoutType.SINGLE_POST}"
                          (click)="onLayoutSelect(LayoutType.SINGLE_POST)"
                          [attr.aria-pressed]="layout === LayoutType.SINGLE_POST"
                          role="radio">
                    <div class="layout-icon-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none" aria-hidden="true" role="img">
                        <rect x="0.15918" y="10.3501" width="66" height="46" rx="1" fill="#020620" fill-opacity="0.3"/>
                        <mask id="path-2-inside-1_3779_2879" fill="white">
                        <rect x="33.0723" y="10.3501" width="33.0869" height="46" rx="1"/>
                        </mask>
                        <rect x="33.0723" y="10.3501" width="33.0869" height="46" rx="1" fill="white" stroke="#020620" stroke-opacity="0.3" stroke-width="4" mask="url(#path-2-inside-1_3779_2879)"/>
                      </svg>
                    </div>
                    <div class="layout-label">Single Post</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="attribute-editor-row has-feedback">
          <div class="form-group" [ngClass]="{ 'has-error': !isValidNumberOfPosts() }">
            <label class="control-label" for="te-number-of-posts">Number of posts:</label>
            <input type="number" id="te-number-of-posts" class="form-control" name="te-number-of-posts"
            min="{{MIN_POSTS}}" max="{{MAX_POSTS}}" step="1"
            [(ngModel)]="numberOfPosts"
            (change)="saveInputIfValid($event)">
            @if (!isValidNumberOfPosts()) {
              <p class="help-block">
                <span>Please provide a number between {{MIN_POSTS}} and {{MAX_POSTS}}.</span>
              </p>
            }
          </div>
        </div>

        <div class="attribute-editor-row">
          <div class="form-group">
            <label class="control-label" id="show-caption-label">Show caption:</label>
            <mat-slide-toggle class="pl-3" [checked]="showCaption" (change)="toggleShowCaption()" aria-labelledby="show-caption-label"></mat-slide-toggle>
          </div>
        </div>

        <div class="attribute-editor-row">
          <div class="form-group">
            <label class="control-label" id="hide-videos-label">Hide videos:</label>
            <mat-slide-toggle class="pl-3" [checked]="hideVideos" (change)="toggleHideVideos()" aria-labelledby="hide-videos-label"></mat-slide-toggle>
          </div>
        </div>

        <div class="attribute-editor-row">
          <div class="form-group">
            <label class="control-label" id="shuffle-order-label">Shuffle order:</label>
            <mat-slide-toggle class="pl-3" [checked]="shuffleOrder" (change)="toggleShuffleOrder()" aria-labelledby="shuffle-order-label"></mat-slide-toggle>
          </div>
        </div>

        <div class="attribute-editor-row" [shown]="layout === LayoutType.SINGLE_POST">
          <div class="form-group" [ngClass]="{ 'has-error': !isValidDuration() }">
            <label class="control-label" for="te-duration">Show each post for:</label>
            <div class="input-group">
              <input type="number" id="te-duration" class="form-control"
              min="{{MIN_DURATION}}" max="{{MAX_DURATION}}" step="1"
              [(ngModel)]="duration"
              (change)="saveInputIfValid($event)">
              <span class="input-group-addon">Seconds</span>
            </div>
            @if (!isValidDuration()) {
              <p class="help-block">
                <span>Please provide a number between {{MIN_DURATION}} and {{MAX_DURATION}}.</span>
              </p>
            }
          </div>
        </div>

        <div class="attribute-editor-row" [shown]="layout === LayoutType.WATERFALL">
          <div class="form-group">
            <label class="control-label">Scroll speed:</label>
            <select class="form-control"
              [(ngModel)]="scrollSpeed"
              (ngModelChange)="save()">
              <option value="slow">Slow</option>
              <option value="medium">Medium</option>
              <option value="fast">Fast</option>
            </select>
          </div>
        </div>

        <div class="attribute-editor-row">
          <div class="form-group">
            <label class="control-label">Hide old posts:</label>
            <select class="form-control"
              [(ngModel)]="hideOldPosts"
              (ngModelChange)="save()">
              <option value="">Do not hide</option>
              <option value="1 days">1 Day Ago</option>
              <option value="2 days">2 Day Ago</option>
              <option value="3 days">3 Day Ago</option>
              <option value="4 days">4 Day Ago</option>
              <option value="5 days">5 Day Ago</option>
              <option value="6 days">6 Day Ago</option>
              <option value="7 days">7 Day Ago</option>
              <option value="1 months">One Month</option>
            </select>
          </div>
        </div>

        <div class="attribute-editor-row">
          <div class="form-group">
            <label class="control-label">Refresh Interval:</label>
            <select class="form-control"
              [(ngModel)]="refresh"
              (ngModelChange)="save()">
              <option value="60">1 Hour</option>
              <option value="180">3 Hours</option>
              <option value="360">6 Hours</option>
              <option value="720">12 Hours</option>
            </select>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div id="addFeedView" [shown]="currentView === 'add_feed'" class="social-media-posts-container scrollable-component-container">
    <div class="attribute-editor-component " >

      @if(selectedFeed?.type === FeedType.FB_PAGE || selectedFeed?.type === FeedType.FB_USER) {
        <div class="attribute-editor-row scrollable-component-settings te-scrollable-container page-body page-body-centered">

          <mat-icon aria-hidden="true" aria-label="Facebook Logo" svgIcon="facebook" class="large"></mat-icon>
          <div class="add-feed-name">
            @if(selectedFeed?.type === FeedType.FB_PAGE) {
              Facebook - Page
            }
            @else {
              Facebook - Profile
            }
          </div>
          <div class="add-feed-title">
            Connect to Facebook to Pull the content for this feed?
          </div>
          <div class="add-feed-disclaimer">
            We'll never post anything on Facebook. Rise Vision does not access, store, or view personal information or passwords that you may use to connect your accounts.
          </div>
        </div>

        <div class="attribute-editor-row">
          <button id="symbolSelectorButton" class="btn btn-connect-facebook btn-block d-flex align-items-center justify-content-center" (click)="connect()">
            <mat-icon aria-hidden="true" svgIcon="facebook-inverted" class="icon-left icon-connect large"></mat-icon>
            <strong>Connect with Facebook</strong>
          </button>
        </div>
      }
      @else if(selectedFeed?.type === FeedType.IG_PAGE) {
        <div class="attribute-editor-row scrollable-component-settings te-scrollable-container page-body page-body-centered">
          <mat-icon aria-hidden="true" aria-label="Instagram Logo" svgIcon="instagram" class="large"></mat-icon>
          <div class="add-feed-name">
            Instagram
          </div>
          <div class="add-feed-title">
            Connect to Instagram to pull the content for this feed?
          </div>
          <div class="add-feed-disclaimer">
            We'll never post anything on Instagram. Rise Vision does not access, store, or view personal information or passwords that you may use to connect your accounts.
          </div>
        </div>

        <div class="attribute-editor-row">
          <button class="btn btn-connect-instagram btn-block d-flex align-items-center justify-content-center" (click)="connect()">
            <mat-icon aria-hidden="true" svgIcon="instagram-inverted" class="icon-left icon-connect large"></mat-icon>
            <strong>Connect with Instagram</strong>
          </button>
        </div>
      }
    </div>
  </div>

  <div id="selectPageView" [shown]="currentView === 'select_page'" class="social-media-posts-container scrollable-component-container">
    <div class="attribute-editor-component">
      <div class="attribute-editor-row scrollable-component-settings te-scrollable-container page-body">

        @if(pagesApiFailed) {
          <div class="form-group has-error">
            <p class="help-block">
              <span>Something went wrong.</span>
            </p>
          </div>
        }

        <div class="select-page-title" id="select-facebook-page-label">
          Select a Facebook page
        </div>
        <div>
          <mat-radio-group
            aria-labelledby="select-facebook-page-label"
            class="select-page-radio-group"
            [(ngModel)]="selectedPage">
            @for (page of availablePages; track page.id) {
              <mat-radio-button class="select-page-radio-button" [value]="page">
                <div>
                  <img [src]="page.picture"
                       class="mr-3 select-page-picture"
                       alt="Profile picture"
                       onerror="this.style.display='none'">
                  <span>{{page.name}}</span>
                </div>
              </mat-radio-button>
            }
          </mat-radio-group>
          <div class="text-sm" [shown]="!availablePages?.length && !pagesApiFailed">
            <span>There are no pages in this account.</span>
          </div>
        </div>
      </div>

      <div class="attribute-editor-row">
        <button id="symbolSelectorButton" class="btn btn-primary btn-block" (click)="addSelectedPage()" [disabled]="!selectedPage">
          <strong>Add Selected Page</strong>
        </button>
      </div>

    </div>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageApiService } from 'src/app/api/services/storage-api.service';
import { StorageUtilsService } from '../../services/storage-utils.service';

@Component({
  selector: 'new-folder-modal',
  templateUrl: './new-folder-modal.component.html',
  styleUrls: ['./new-folder-modal.component.scss']
})
export class NewFolderModalComponent {

  folderName = '';

  folderPath = '';
  existingFiles = [];

  duplicateFolderSpecified = false;
  waitingForResponse = false;
  serverError = false;
  accessDenied = false;
  accessDeniedMessage = '';
  respCode: number;

  constructor(
    public dialogRef: MatDialogRef<NewFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewFolderModalData,
    private storageApiService: StorageApiService
  ) {
    this.folderPath = data.folderPath;
    this.existingFiles = data.existingFiles;
  }

  private _fileNameExists (fileName) {
    for (var i = 0; i < this.existingFiles.length; i++) {
      if (this.existingFiles[i].name === fileName) {
        return true;
      }
    }
    return false;
  }

  ok () {
    if (!this.folderName) {
      this.folderName = '';
    }
    this.folderName = this.folderName.replace(/\//g, '');
    if (this._fileNameExists(this.folderName + '/')) {
      this.duplicateFolderSpecified = true;
      return;
    }
    if (this.folderName !== '') {
      this.waitingForResponse = true;

      this.storageApiService.createFolder(this.folderPath + this.folderName)
        .then((resp) => {

          if (resp.code === 200) {
            this.dialogRef.close(this.folderName);

          } else if (resp.code === 403 && resp.message.indexOf('restricted-role') === -1) {
            this.accessDenied = true;
            this.accessDeniedMessage = StorageUtilsService.STORAGE_CLIENT_ERROR[resp.message];
          } else if (resp.code === 403) {
            this.accessDenied = true;
          } else {
            this.respCode = resp.code;
            this.serverError = true;
          }
        }).finally(() => {
          this.waitingForResponse = false;
        });
    }
  }

  cancel () {
    this.dialogRef.close(false);
  }
}

interface NewFolderModalData {
  folderPath: string,
  existingFiles: any[]
}

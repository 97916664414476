<div resizeObserver (resizeEvent)="onHeaderResize($event)">
  @if (allowUpload) {
    <upload #upload [folderPath]="folderPath" (addFile)="addFile($event)" (queueLengthChange)="uploadQueueLength = $event"></upload>
  }
  <div [shown]="filesDetails.apiError" class="madero-style alert alert-danger text-center" role="alert">
    <p><strong>Failed to load files</strong></p>
    {{filesDetails.apiError}}. If the problem persists, please
    <a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a>.
  </div>
  <div class="tw-flex tw-flex-col md:!tw-flex-row tw-pb-8 tw-gap-5 tw-items-center">
    <search-filter #searchFilter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="search.doSearch" [localSearch]="true" [shown]="isFileListVisible()" />
    @if (allowUpload) {
      <div class="search-buttons tw-gap-4" [ngClass]="{'disabled': isTrashFolder}">
        <button class="tw-min-w-64" tabindex="0" id="newFolderButton" mat-stroked-button (click)="addFolder()" [disabled]="isTrashFolder">
          <mat-icon svgIcon="add-folder" /> Create Folder
        </button>
        <button class="tw-min-w-64" tabindex="0" mat-stroked-button id="uploadFolderButtonUnsubscribed" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          <mat-icon svgIcon="upload" /> Upload Folder
        </button>
        <button class="tw-min-w-64" tabindex="0" mat-stroked-button id="uploadFolderButton" (click)="uploadComponent?.openFolderSelector()" [shown]="currentPlanService.isPlanActive()">
          <mat-icon svgIcon="upload" /> Upload Folder
        </button>
        <button class="tw-min-w-64" tabindex="0" id="uploadButtonUnsubscribed" mat-flat-button color="primary" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          <mat-icon svgIcon="upload" /> Upload Files
        </button>
        <button class="tw-min-w-64" tabindex="0" id="uploadButton"  mat-flat-button color="primary" (click)="uploadComponent?.openFileSelector()" [shown]="currentPlanService.isPlanActive()">
          <mat-icon svgIcon="upload" /> Upload Files
        </button>
      </div>
    }
  </div>
  <div class="tw-flex tw-flex-row light-border tw-py-6" [shown]="isFileListVisible()">
    <div class="flex-row-entry flex-grow">
      <storage-breadcrumb [folderPath]="folderPath" (folderClicked)="changeFolder({name: $event})"></storage-breadcrumb>
    </div>
    @if (storageService.isMultipleSelector() && !allowBatchOperations && filesDetails.checkedItemsCount > 0) {
      <div class="flex-row-entry pr-4">
        {{ filesDetails.checkedItemsCount }} Selected
      </div>
    }
    <div class="flex-row-entry" [shown]="filesDetails.bucketExists && allowUpload">
      <list-grid-toggle [(isListView)]="isListView"></list-grid-toggle>
    </div>
  </div>
  <div [shown]="files.apiError && filesDetails.checkedItemsCount > 0" class="madero-style alert alert-danger text-center tw-mt-8" role="alert">
    <p><strong>{{files.errorMessage}}</strong><br>{{files.apiError}}</p>
  </div>
  <div id="files-toolbar" class="flex-row" [hidden]="fileListStatus.code === 404">
    @if (allowBatchOperations) {
      <batch-operations class="w-100" [listObject]="files"></batch-operations>
    }
  </div>
</div>
<div #filesListContainer rvSpinner="storage-selector-loader" [rvShowSpinner]="files.loadingItems">
  @if (isListView) {
    <cdk-virtual-scroll-viewport [itemSize]="68" class="scrollable-list horizontal-scroll u_margin-md-top u_margin-md-bottom grid-selector">
      <storage-list-view
        [files]="files.items.list"
        [folderPath]="folderPath"
        [filesDetails]="filesDetails"
        [fileListVisible]="isFileListVisible()"
        [search]="search"
        [selectAll]="files.search.selectAll"
        (selectAllCheckboxes)="files.selectAll()"
        (fileClick)="fileClick($event)">
      </storage-list-view>
    </cdk-virtual-scroll-viewport>
  }
  @else {
    <div class="scrollable-list horizontal-scroll u_margin-md-top u_margin-md-bottom grid-selector">
      <storage-grid-view
        [files]="files.items.list"
        [folderPath]="folderPath"
        [filesDetails]="filesDetails"
        [fileListVisible]="isFileListVisible()"
        [search]="search"
        [isEmpty]="!files.loadingItems && isEmptyState()"
        (fileClick)="fileClick($event)"
        (uploadFiles)="uploadComponent.openFileSelector()">
      </storage-grid-view>
    </div>
  }
</div>
<div id="addWidgetByUrlModal">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button id="dismissButton" mat-icon-button type="button" class="close" (click)="dismiss()" aria-label="Close this modal">
      <streamline-icon ariaLabel="Close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4>Add a Custom Rise Widget</h4>
  <form #widgetItemForm="ngForm" id="widgetItemForm" role="form" name="forms.widgetItemForm" novalidate>
    <mat-dialog-content>

      <div class="form-group" [ngClass]="{'has-error': !url.pristine && url.invalid}" show-errors>
        <label id="urlLabel" class="control-label">URL:</label>
        <input id="urlInput" #url="ngModel" name="url" type="url" class="form-control" placeholder="http://" [(ngModel)]="form.url" required [pattern]="WEBSITE_REGEX">
        <p id="warningRequired" class="text-danger" [shown]="!url.pristine && url.errors?.['required']">Required</p>
        <p id="warningInvalidUrl" class="text-danger" [shown]="!url.pristine && url.errors?.['pattern']">Invalid URL</p>
      </div><!--form-control-->

      <div class="form-group" [ngClass]="{'has-error': !settingsUrl.pristine && settingsUrl.invalid}" show-errors>
          <label id="settingsUrlLabel" class="control-label">Settings URL:</label>
          <input id="settingsUrlInput" #settingsUrl="ngModel" name="settingsUrl" type="url" class="form-control" placeholder="http://" [(ngModel)]="form.settingsUrl" [pattern]="WEBSITE_REGEX">
          <p class="text-danger" [shown]="!settingsUrl.pristine && settingsUrl.errors?.['pattern']">Invalid URL</p>
        </div><!--form-control-->
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="tw-gap-4">

      <button id="formCancelButton" mat-stroked-button type="button" (click)="dismiss()">Cancel</button>
      <button id="applyButton" mat-flat-button color="primary" type="submit" (click)="apply()" [disabled]="widgetItemForm.invalid">Apply</button>
    </mat-dialog-actions>
  </form>

</div>

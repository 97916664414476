import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[resizeObserver]'
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
  private resizeObserver: ResizeObserver;

  @Output() resizeEvent = new EventEmitter<{ width: number, height: number }>();

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        for (const entry of entries) {
          this.resizeEvent.emit({
            width: entry.contentRect.width,
            height: entry.contentRect.height
          });
        }
      });
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';


@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() isMultipleFilesFoldersSelector: boolean;
  @Input() search: { query: string };
  @Output() resetSearch = new EventEmitter<void>();
  @Output() uploadFiles = new EventEmitter<void>();

  constructor(protected currentPlanService: CurrentPlanService) {}
}
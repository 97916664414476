import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from 'src/app/components/modals/modal.service';
import { StorageUtilsService } from '../../services/storage-utils.service';

@Component({
  selector: 'copy-url-modal',
  templateUrl: './copy-url-modal.component.html',
  styleUrls: ['./copy-url-modal.component.scss']
})
export class CopyUrlModalComponent {

  copyFile: any;

  @ViewChild('copyUrlInput') copyUrlInput: ElementRef;

  get copyUrl(): string {
    return this.copyFile.kind === 'folder' ?

      this.storageUtilsService.getFolderSelfLinkUrl() +
      this._encodeURIKeepForwardSlash(this.copyFile.name) :

      StorageUtilsService.STORAGE_FILE_URL +
      this.storageUtilsService.getBucketName() + '/' +
      this._encodeURIKeepForwardSlash(this.copyFile.name);
  }

  constructor (
    public dialogRef: MatDialogRef<CopyUrlModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { copyFile: any },
    private modalService: ModalService,
    private storageUtilsService: StorageUtilsService
  ) {
    this.copyFile = data.copyFile;
  }

  private _encodeURIKeepForwardSlash (uri: string) {
    return encodeURIComponent(uri).split('%2F').join('/');
  }

  selectInput () {
    setTimeout(() => {
      this.copyUrlInput.nativeElement.select();
    });
  }

  copyToClipboard (event: Event) {
    // Prevent mousedown from deselecting the text
    event.preventDefault();

    const selection = document.activeElement.id === 'copyUrlInput' && window.getSelection().toString() || this.copyUrl;
    window.navigator.clipboard.writeText(selection);
    this.modalService.showMessage('URL Copied', 'The URL has been copied to your clipboard.').then(() => {
      this.copyUrlInput.nativeElement.select();
    });
  }

  cancel () {
    this.dialogRef.close(false);
  }
}

import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { type SidenavState } from '../app/app.component';
import { MatMenuTrigger } from '@angular/material/menu';

interface NavOption {
  id: string;
  label: string;
  route?: string;
  icon?: string;
  tooltip?: string;
  addon?: string;
  addonClass?: string;
  states?: string[];
  clickHandler?: () => void;
}

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnChanges {
  @Input() state: SidenavState = 'expand';
  @Input() parentAnimating = false;
  @Input() currentRoute: string = '';
  @Input() hideNavigation: boolean = false;
  @Output() companyMenuOpened = new EventEmitter<boolean>();
  @ViewChild(MatMenuTrigger) companyMenuTrigger: MatMenuTrigger;

  animatingItems = false;
  animationState: string = 'expand';
  isCompanyMenuOpen = false;

  protected navOptions: Record<string, NavOption> = {};

  private refreshNavOptions() {
    this.navOptions = {
      'presentations': {
        id: 'presentations-link',
        label: 'Presentations',
        route: 'apps.editor.home',
        icon: 'presentations',
        states: [
          'apps.editor.home',
          'apps.editor.list',
          'apps.editor.workspace.artboard',
          'apps.editor.workspace.htmleditor',
          'apps.editor.templates.edit'
        ]
      },
      'schedules': {
        id: 'schedules-link',
        label: 'Schedules',
        route: 'apps.schedules.home',
        icon: 'calendar',
        states: [
          'apps.schedules.home',
          'apps.schedules.list',
          'apps.schedules.details',
          'apps.schedules.add'
        ]
      },
      'displays': {
        id: 'displays-link',
        label: 'Displays',
        route: 'apps.displays.home',
        icon: 'display',
        states: [
          'apps.displays.home',
          'apps.displays.list',
          'apps.displays.add',
          'apps.displays.activate',
          'apps.displays.details'
        ]
      },
      'screen-sharing': {
        id: 'screen-sharing-link',
        label: 'Screen Share',
        tooltip: 'Screen Share',
        route: 'apps.screen-sharing.moderator-join',
        icon: 'screen-share',
        states: [
          'apps.screen-sharing.moderator-join',
          'apps.screen-sharing.moderator-room'
        ]
      },
      'screen-sharing-upgrade': {
        id: 'screen-sharing-upgrade-button',
        label: 'Screen Share',
        icon: 'screen-share',
        addon: 'requires-upgrade',
        addonClass: 'small',
        tooltip: 'Upgrade to access Screen Sharing',
        clickHandler: this.upgradePlan.bind(this)
      },
      'storage': {
        id: 'storage-link',
        label: 'Storage',
        route: 'apps.storage.home',
        icon: 'cloud-storage',
        states: ['apps.storage.home']
      },
      'cap-alerts': {
        id: 'cap-alerts-link',
        label: 'CAP Alerts',
        route: 'apps.displays.alerts',
        icon: 'cap-alerts',
        states: ['apps.displays.alerts']
      }
    };
  }

  constructor(
    protected companyStateService: CompanyStateService,
    protected userStateService: UserStateService,
    protected featuresService: FeaturesService,
    protected rvshareAppDetectionService: RvshareAppDetectionService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['state']) {
      this.animationState = this.state;
      this.animatingItems = true;

      if (this.isCompanyMenuOpen) {
        this.updateMenuPosition();
      }
    }
    if (changes['currentRoute']) {
      if (!this.hideNavigation) {
        this.refreshNavOptions();
      }
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.isCompanyMenuOpen) {
      this.updateMenuPosition();
    }
  }

  private updateMenuPosition(): void {
      const viewportWidth = window.innerWidth;
      let marginLeft = this.state === 'collapse' ? 50 : 250;

      // For smaller screens, ensure menu stays in viewport
      if (viewportWidth < 768) {
        marginLeft = Math.min(marginLeft, Math.max(10, viewportWidth - 420));
      }

      document.documentElement.style.setProperty(
        '--company-menu-margin-left',
        `${marginLeft}px`
      );
  }

  handleMenuOpened() {
    this.isCompanyMenuOpen = true;
    this.updateMenuPosition();
    this.companyMenuOpened.emit(true);
  }

  handleMenuClosed() {
    this.isCompanyMenuOpen = false;
    this.companyMenuOpened.emit(false);
  }

  handleCloseMenu() {
    this.companyMenuTrigger.closeMenu();
  }

  upgradePlan() {
    this.featuresService.showUpgradePlanModal('screen_sharing');
  }

  onSidebarAnimationStart(event: AnimationEvent): void {
    if (event.toState === 'expand' || event.toState === 'collapse') {
      this.animatingItems = true;
    }
  }

  onSidebarAnimationDone(): void {
    this.animatingItems = false;
  }
}

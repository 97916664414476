import { Injectable, WritableSignal } from '@angular/core';
import { LazyLoadService } from 'src/app/ajs-upgraded-providers';
import { sortBy } from 'lodash';

export type VideoStreamingValidationStatus =
  | 'VALID'
  | 'INVALID'
  | 'NOT_PUBLIC'
  | 'VIDEO_NOT_FOUND'
  | 'NOT_SUPPORTED';

export interface VideoStreamingMedia {
  id: string;
  name: string;
  language: string;
  type?: string;
  index: number;
}

const HLSJS_PATH = 'vendor/hls.js/hls.min.js';

@Injectable({
  providedIn: 'root',
})
export class VideoStreamingValidationService {
  constructor(private lazyLoadService: LazyLoadService) {}

  validateHLSUrl(url: string) {
    const regex =
      /^(?:https:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?\.m3u8(?:\?[^\s]*)?$/;
    return regex.test(url);
  }

  async validateHLSContent(
    source: string,
    captionTracks: WritableSignal<VideoStreamingMedia[]>,
    audioTracks: WritableSignal<VideoStreamingMedia[]>
  ): Promise<VideoStreamingValidationStatus> {
    try {
      await this.lazyLoadService.load(HLSJS_PATH);
      const Hls = window.Hls;

      if (Hls.isSupported()) {
        const videoContainer = <HTMLVideoElement>(
          document.getElementById('videoContainer')
        );
        if (!videoContainer) return 'INVALID';
        videoContainer.innerHTML = '';

        const video = document.createElement('video');
        video.id = 'video';
        videoContainer.appendChild(video);

        const hls = new Hls();
        const cleanup = () => {
          hls.destroy();
          video.textTracks.onaddtrack = null;
        };

        hls.loadSource(source);
        hls.attachMedia(video);

        return new Promise((resolve) => {
          const timeout = setTimeout(() => {
            cleanup();
            resolve('INVALID');
          }, 15000); // 15 seconds timeout

          hls.on(Hls.Events.ERROR, (error, errorData) => {
            clearTimeout(timeout);
            cleanup();
            const result =
              errorData.details === 'manifestLoadError'
                ? 'VIDEO_NOT_FOUND'
                : 'INVALID';
            resolve(result);
          });

          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            clearTimeout(timeout);
            audioTracks.set([]);
            captionTracks.set([]);
            resolve('VALID');
          });

          // hls.js subtitleTracks array doesn't include embedded captions,
          // so we need to get them through the onaddtrack event from video.textTracks
          video.textTracks.onaddtrack = (event) => {
            if (!['captions', 'subtitles'].includes(event.track.kind)) return;

            // When feeding the video element with the subtitles, hls.js doens't
            // set the id of the caption, so we need to find it for the case of embedded captions.
            // Embedded captions have the property textTrackN, where N is a number starting at 1,
            // which is the ID of the subtitle. Other subtitle IDs won't be used for selection.
            let subtitleId;
            for (let i = 1; i <= 10; i += 1) {
              if (event.track[`textTrack${i}`]) {
                subtitleId = `textTrack${i}`;
              }
            }

            captionTracks.update((value) =>
              sortBy(
                [
                  ...value,
                  {
                    name: event.track.label,
                    id: subtitleId,
                    language: event.track.language,
                    type: event.track.kind,
                    index: value.length,
                  },
                ],
                'name'
              )
            );
          };

          hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (event, data) => {
            audioTracks.update((value) =>
              sortBy(
                [
                  ...value,
                  ...data.audioTracks.map((track, index) => ({
                    name: track.name,
                    id: track.id.toString(),
                    language: track.lang,
                    index,
                  })),
                ],
                'name'
              )
            );
          });
        });
      } else {
        return 'NOT_SUPPORTED';
      }
    } catch (err) {
      return this.validateApiError(err);
    }
  }

  private validateApiError(err: {
    status: number;
  }): VideoStreamingValidationStatus {
    if (err.status === 401) return 'NOT_PUBLIC';
    return 'INVALID';
  }
}

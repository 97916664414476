<div rvSpinner="company-settings" [rvShowSpinner]="companyService.loading">
  <div class="company-settings">
    <form #companyForm="ngForm" id="forms.companyForm" role="form" name="forms.companyForm" (ngSubmit)="save()"
      autocomplete="on" novalidate>
      <common-actions
        [(title)]="companyService.company.name"
        [actions]="actions"
        [saving]="companyService.loading"
        [changeDate]="companyService.company?.changeDate"
        [changedBy]="companyService.company?.changedBy">
      </common-actions>
      <div id="errorBox" class="alert alert-danger" role="alert" [shown]="companyService.formError">
        <strong>{{companyService.formError}}</strong> {{companyService.apiError}}
        @if (companyService.isAddressError) {
          <suggest-general-delivery [addressObject]="companyService.company"></suggest-general-delivery>
        }
      </div>
      <company-fields [company]="companyService.company" showManagedSections="true" (resetAuthKeyEvent)="resetAuthKey()"
        (resetClaimIdEvent)="resetClaimId()">
      </company-fields>
    </form>
  </div>
</div>

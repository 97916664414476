import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';

@Component({
  selector: 'change-details',
  templateUrl: './change-details.component.html',
  styleUrl: './change-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeDetailsComponent {

  date = signal<Date | null>(null);
  username = signal<string | null>(null);
  isToday = signal<boolean>(false);

  @Input() set changedBy (value: string) {
    this.username.set(value);
  }
  @Input({required: true}) set changeDate(value: string) {
    if (!value) {
      this.isToday.set(false);
      this.date.set(null);
      return;
    }
    this.date.set(new Date(value));
    const today = new Date();

    this.isToday.set(today.toDateString() === this.date().toDateString());
  }


}

import { Component, Input } from '@angular/core';

import { ModalService } from 'src/app/components/modals/modal.service';

import { TimelineDescriptionService } from 'src/app/components/timeline/timeline-description.service';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent {

  @Input() playlistItems: any;

  constructor(private modalService: ModalService,
    public playlistService: PlaylistService,
    public timelineDescription: TimelineDescriptionService) {
  }

  manage(playlistItem) {
    this.playlistService.editPlaylistItem(playlistItem);
  }

  remove(playlistItem) {
    this.modalService.confirm('Remove Playlist Item?',
      'Are you sure you want to remove this item from the Playlist?',
      'Yes', 'No', 'removeButton'
    ).then(() => {
      this.playlistService.removePlaylistItem(playlistItem);
    });
  }

  sortItem(evt) {
    const oldIndex = evt.data.oldIndex;
    const newIndex = evt.data.newIndex;

    this.playlistService.moveItem(oldIndex, newIndex);
  }

  isAppleTVCompatible(playlistItem) {
    return this.playlistService.isItemAppleTVCompatible(playlistItem);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'list-grid-toggle',
  templateUrl: './list-grid-toggle.component.html',
  styleUrls: ['./list-grid-toggle.component.scss']
})
export class ListGridToggleComponent {

  @Input() set isListView(value: boolean) {
    this.value = value ? 'list' : 'grid';
  }
  @Output() isListViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected value = 'grid';

  toggleListView (value: 'list' | 'grid') {
    this.value = value;
    this.isListViewChange.emit(value === 'list');
  }
}
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { ModalService } from 'src/app/components/modals/modal.service';

import { StateService, TransitionService } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { BulkDeleteConfirmationComponent } from '../bulk-delete-confirmation/bulk-delete-confirmation.component';

@Component({
  selector: 'batch-operations',
  templateUrl: './batch-operations.component.html',
  styleUrls: ['./batch-operations.component.scss']
})
export class BatchOperationsComponent implements OnInit, OnDestroy {
  @Input() listObject!: any;
  @Input() styles: string = 'xl:!tw-min-w-[850px]';

  additionalOperations: any[] = [];
  transitionHook: any;

  constructor(private stateService: StateService,
    private transitionService: TransitionService,
    private modalService: ModalService,
    private userStateService: UserStateService) {
  }

  ngOnInit(): void {
    if (this.listObject && this.listObject.batchOperations &&
      this.listObject.batchOperations.operations) {
      this._filterByRole();
      this._updateDeleteAction();

      this.additionalOperations = this.listObject.batchOperations.operations
        .filter((op: any) => !op.mainOperation);
    }

    let _bypass = false;
    this.transitionHook = this.transitionService.onStart({}, (trans: any) => {
      if (_bypass) {
        _bypass = false;
        return;
      }
      if (this.listObject.batchOperations.activeOperation) {
        var operationName = this.listObject.batchOperations.activeOperation.name.toLowerCase();

        trans.abort();

        this.modalService.confirm('Cancel bulk ' + operationName + '?',
          'A bulk ' + operationName +
          ' is in progress. Navigating away from this page will cancel this action. Are you sure you want to cancel?',
          'Yes, Cancel',
          'No')
          .then(() => {
            this.listObject.batchOperations.cancel();

            _bypass = true;
            this.stateService.go(trans.to().name, trans.to().params);
          });
      }
    });

    window.onbeforeunload = (e: Event) => {
      if (this.listObject.batchOperations.activeOperation) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
  }

  ngOnDestroy(): void {
    window.onbeforeunload = undefined;

    this.transitionHook();
  }

  _filterByRole() {
    _.remove(this.listObject.batchOperations.operations, (operation: any) => {
      return !this.userStateService.hasPermission(operation.requireRole);
    });
  }

  _updateDeleteAction() {
    _.each(this.listObject.batchOperations.operations, (operation) => {
      if (operation.isDelete) {
        operation.beforeBatchAction = () => {
          return this.modalService.showDialog(BulkDeleteConfirmationComponent, {
            selectedItems: this.listObject.getSelected(),
            itemName: this.listObject.batchOperations.name
          }, { width: this.modalService.MODAL_SM });
        };
      }
    });
  }

  executeOperation(operation: any): void {
    if (operation && operation.onClick) {
      operation.onClick();
    }
  }

  hasAvailableOperations(): boolean {
    const mainOps = this.listObject?.batchOperations?.operations
      ?.filter(op => op.mainOperation)
      ?.filter(op => !(op.hidden && op.hidden()))
      ?.length > 0;

    const additionalOps = this.additionalOperations
      ?.filter(op => !(op.hidden && op.hidden()))
      ?.length > 0;

    return mainOps || additionalOps;
  }

  getOperationIcon(operationName: string): string {
    const iconMap: { [key: string]: string } = {
      'Delete': 'trash',
      'Move': 'move-to-folder',
      'License': 'license',
      'Restart Rise Player': 'restart',
      'Reboot Media Player': 'reboot',
      'Export': 'export-all',
      'Export All': 'export-all',
      'Copy': 'duplicate',
      'Screen Sharing': 'screen-share',
      'Set Monitoring': 'monitoring',
      'Set Reboot Time': 'reboot-time',
      'Set Address': 'set-address',
      'Enable Display Control': 'enable-display',
      'Disable Display Control': 'disable-display',
      'Move To Company': 'move-display',
      'Move To Trash': 'trash',
      'Assign Schedule': 'calendar',
      'Download': 'download',
      'Duplicate': 'duplicate',
      'Copy URL': 'copy',
      'Rename': 'edit'
    };

    return iconMap[operationName] || 'alert-triangle';
  }

}
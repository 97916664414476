import { StatesModule, Transition } from '@uirouter/angular';

import { CanAccessApps } from '../ajs-upgraded-providers';

import { LocationHelperService } from '../shared/services/location-helper.service';
import { ViewportComponent } from './viewport/viewport.component';

export const routes: StatesModule = {
  states: [
    {
      name: 'apps',
      url: '?cid',
      params: {
        cid: {
          dynamic: true
        }
      },
      abstract: true,
      component: ViewportComponent,
      data: {
        requiresAuth: true
      },
    },
    {
      name: 'apps.home',
      url: '/',
      redirectTo: 'apps.editor.home'
    },
    {
      name: 'apps.auth.signup',
      url: '/signup',
      resolve: [{
        token: 'authenticate',
        deps: [Transition, CanAccessApps, LocationHelperService],
        resolveFn: (transition, canAccessApps, locationHelperService) => {
          const showProduct = locationHelperService.getSearchParam('show_product');

          return canAccessApps(true).then(() => {
            transition.abort();
            if (showProduct) {
              return transition.router.stateService.go('apps.purchase.plans');
            } else {
              return transition.router.stateService.go('apps.home');
            }
          });
        }
      }]
    },
    {
      name: 'apps.auth.signin',
      url: '/signin',
      resolve: [{
        token: 'authenticate',
        deps: [CanAccessApps, Transition],
        resolveFn: (canAccessApps, transition) => {
          return canAccessApps().then(() => {
            transition.abort();
            return transition.router.stateService.go('apps.home');
          });
        }
      }]
    }
  ]
};

import { Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/angular';

export type RouteMetadata = {
  title: string;
  hidePageHeader?: boolean;
  backLink?: string;
  backLinkText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RouteMetadataService {

  constructor(
    private uiRouterGlobals: UIRouterGlobals
  ) { }

  public getActiveRouteMetadata(): RouteMetadata | undefined {
    const routeName = this.uiRouterGlobals.current.name;

    return this.getRouteMetadata(routeName);
  }

  public getRouteMetadata(routeName: string): RouteMetadata | undefined {
    switch (routeName) {
      // Auth routes
      case 'apps.auth':
      case 'apps.auth.unauthorized':
      case 'apps.auth.signin':
        return {
          title: 'Sign In',
          hidePageHeader: true
        };

      case 'apps.auth.createaccount':
      case 'apps.auth.joinaccount':
      case 'apps.auth.signup':
      case 'apps.auth.unregistered':
        return {
          title: 'Sign Up',
          hidePageHeader: true
        };

      case 'apps.auth.requestpasswordreset':
      case 'apps.auth.resetpassword':
        return {
          title: 'Reset Password',
          hidePageHeader: true
        };

      case 'apps.auth.unsubscribe':
        return {
          title: 'Unsubscribe',
          hidePageHeader: true
        };

      case 'apps.auth.confirmaccount':
      case 'apps.auth.provision':
        return {
          title: 'Account Confirmation',
          hidePageHeader: true
        };

      // Main app routes
      case 'apps':
      case 'apps.home':
        return {
          title: 'Home',
          hidePageHeader: true
        };

      case 'apps.selector':
        return {
          title: 'Selector',
          hidePageHeader: true
        };

      case 'apps.displays.alerts':
        return {
          title: 'CAP Alerts'
        };

      case 'apps.billing':
      case 'apps.billing.home':
        return {
          title: 'Account & Billing'
        };

      case 'apps.billing.subscription':
        return {
          title: 'Subscription',
          backLink: 'apps.billing.home',
          backLinkText: 'Back to Account & Billing'
        };

      case 'apps.billing.unpaid':
        return {
          title: 'Unpaid Invoices'
        };

      case 'apps.billing.invoice':
        return {
          title: 'Invoice'
        };

      case 'apps.billing.payment':
        return {
          title: 'Add Payment Source'
        };

      case 'apps.company':
        return {
          title: 'Company'
        };

      case 'apps.company.add':
        return {
          title: 'Add Sub-Company'
        };

      case 'apps.company.details':
        return {
          title: 'Company Settings'
        };

      case 'apps.company.licenses':
        return {
          title: 'Companies & Licenses'
        };

      case 'apps.displays':
      case 'apps.displays.home':
      case 'apps.displays.list':
        return {
          title: 'Displays'
        };

      case 'apps.displays.add':
      case 'apps.displays.activate':
      case 'apps.displays.install':
      case 'apps.displays.bulk-activation':
        return {
          title: 'Add Display',
          backLink: 'apps.displays.home',
          backLinkText: 'Back to Displays'
        };

      case 'apps.displays.change':
      case 'apps.displays.details':
        return {
          title: 'Display Settings',
          backLink: 'apps.displays.home',
          backLinkText: 'Back to Displays'
        };

      case 'apps.editor':
      case 'apps.editor.home':
      case 'apps.editor.list':
        return {
          title: 'Presentations'
        };

      case 'apps.editor.add':
        return {
          title: 'Presentations',
          backLink: 'apps.editor.home',
          backLinkText: 'Back to Presentations'
        };

      case 'apps.editor.workspace':
      case 'apps.editor.workspace.artboard':
      case 'apps.editor.workspace.htmleditor':
      case 'apps.editor.templates':
      case 'apps.editor.templates.edit':
        return {
          title: 'Edit Presentation',
          backLink: 'apps.editor.home',
          backLinkText: 'Back to Presentations'
        };

      case 'apps.purchase':
      case 'apps.purchase.plans':
      case 'apps.purchase.home':
        return {
          title: 'Subscribe',
          hidePageHeader: true
        };

      case 'apps.purchase.licenses':
      case 'apps.purchase.change-plan':
        return {
          title: 'Change Subscription'
        };

      case 'apps.purchase.update-plan':
      case 'apps.purchase.licenses.change':
        return {
          title: 'Change Subscription Type'
        };

      case 'apps.purchase.licenses.add':
        return {
          title: 'Add Display Licenses'
        };

      case 'apps.purchase.licenses.remove':
        return {
          title: 'Remove Display Licenses'
        };

      case 'apps.purchase.frequency':
        return {
          title: 'Adjust Billing Frequency'
        };

      case 'apps.purchase.licenses.unlimited':
        return {
          title: 'Upgrade Subscription'
        };

      case 'apps.schedules':
      case 'apps.schedules.home':
      case 'apps.schedules.list':
        return {
          title: 'Schedules'
        };

      case 'apps.schedules.details':
        return {
          title: 'Schedule Settings',
          backLink: 'apps.schedules.list',
          backLinkText: 'Back to Schedules'
        };

      case 'apps.schedules.add':
        return {
          title: 'Add Schedule',
          backLink: 'apps.schedules.list',
          backLinkText: 'Back to Schedules'
        };

      case 'apps.screen-sharing':
      case 'apps.screen-sharing.home':
      case 'apps.screen-sharing.moderator-join':
      case 'apps.screen-sharing.moderator-room':
        return {
          title: 'Screen Share'
        };

      case 'apps.storage':
      case 'apps.storage.home':
        return {
          title: 'Storage'
        };

      case 'apps.user':
      case 'apps.user.list':
        return {
          title: 'Users'
        };

      case 'apps.user.add':
      case 'apps.user.add2':
        return {
          title: 'Add User',
          backLink: 'apps.user.list',
          backLinkText: 'Back to Users'
        };

      case 'apps.user.details':
        return {
          title: 'User Settings',
          backLink: 'apps.user.list',
          backLinkText: 'Back to Users'
        };

      default:
        return undefined;
    }
  }
}

@if (files.length === 0 && filesDetails?.code!==202 && filesDetails?.code!==404 && fileListVisible) {
  <div class="text-center text-muted u_margin-md-top u_margin-md-bottom">You haven't uploaded any files yet!</div>
}
@if (files.length > 0 && filesDetails?.code!==202 && filesDetails?.code!==404 && fileListVisible) {
  <ng-container *ngTemplateOutlet="list; context: {files: storageUtilsService.filterFolders(files, search, storageService.storageFull)}"></ng-container>
  <ng-container *ngTemplateOutlet="list; context: {files: storageUtilsService.filterFiles(files, search, storageService.storageFull, true)}"></ng-container>
}
@if (isEmpty) {
  <empty-state
    [search]="search"
    [isMultipleFilesFoldersSelector]="storageService.isMultipleFilesFoldersSelector()"
    (uploadFiles)="uploadFiles.emit()"
  />
}

<ng-template #list let-files="files">
  <div id="storageFilesGrid" class="grid-selector-list grid-selector-list_storage tw-mb-8">
    @for (file of files; track file) {
      <div
        (click)="triggerFileClick(file)"
        (keyup.enter)="triggerFileClick(file)"
        tabindex="0"
        class="grid-selector-list-item"
        [class.selected]="file.selected"
        [class.folder]="storageUtilsService.fileIsFolder(file)"
        [matTooltip]="file.name | storageFileName:folderPath + (file.isThrottled ? ' - This file is being requested too many times and is blocked.' : '')">
        <thumbnail-image [file]="file" [folderPath]="folderPath"></thumbnail-image>
      </div>
    }
  </div>
</ng-template>

import { Component, OnDestroy } from '@angular/core';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

@Component({
  selector: 'plan-banner',
  templateUrl: './plan-banner.component.html',
  styleUrls: ['./plan-banner.component.scss']
})
export class PlanBannerComponent implements OnDestroy {

  subscription;

  constructor(private broadcaster: BroadcasterService,
    private currentPlanService: CurrentPlanService) {

    this.plan = this.currentPlanService.currentPlan;
    this.subscription = this.broadcaster.on('risevision.plan.loaded', () => {
      this.plan = this.currentPlanService.currentPlan;
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get planTrialPeriod() {
    return this.plan.trialPeriod + ' ' + (this.plan.trialPeriod === 1 ? 'day' : 'days');
  }

  plan: any = {};
  showPlans = this.currentPlanService.showPurchaseOptions.bind(this.currentPlanService);

  isEnterpriseSubCompany = this.currentPlanService.isEnterpriseSubCompany.bind(this.currentPlanService);

  getVisibleBanner() {
    var banner = 'free';

    if (this.currentPlanService.isParentPlan()) {
      banner = 'parent';
    } else if (this.currentPlanService.currentPlan.isPurchasedByParent) {
      banner = 'parentPurchased';
    } else if (this.currentPlanService.currentPlan.isAllocatedByParent) {
      banner = 'parentAllocated';
    } else if (this.currentPlanService.isCancelledActive()) {
      banner = 'cancelled';
    } else if (this.currentPlanService.isFree() && this.currentPlanService.isCancelled()) {
      banner = 'free';
    } else if (this.currentPlanService.isSubscribed()) {
      banner = 'subscribed';
    } else if (this.currentPlanService.isOnTrial()) {
      banner = 'trial';
    } else if (this.currentPlanService.isTrialExpired()) {
      banner = 'expired';
    } else if (this.currentPlanService.isSuspended()) {
      banner = 'suspended';
    }

    return banner;
  }

}
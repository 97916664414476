import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterModule } from '@uirouter/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { CommonHeaderModule } from '../common-header/common-header.module';
import { ComponentsModule } from '../components/components.module';
import { SchedulesModule } from '../schedules/schedules.module';
import { SharedModule } from '../shared/shared.module';
import { routes } from './displays.routes';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatChipsModule } from '@angular/material/chips';
import { blobDownloaderFactoryProvider } from '../ajs-upgraded-providers';
import { ApiModule } from '../api/api.module';
import { NotificationAlertComponent } from '../components/notification-alert/notification-alert.component';
import { ActivationNoticeComponent } from './components/activation-notice/activation-notice.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { BulkActivationComponent } from './components/bulk-activation/bulk-activation.component';
import { DisplayAddComponent } from './components/display-add/display-add.component';
import { DisplayAddressComponent } from './components/display-address/display-address.component';
import { DisplayControlComponent } from './components/display-control/display-control.component';
import { DisplayDetailsComponent } from './components/display-details/display-details.component';
import { DisplayFieldsComponent } from './components/display-fields/display-fields.component';
import { DisplayLicenseCountsComponent } from './components/display-license-counts/display-license-counts.component';
import { DisplayListComponent } from './components/display-list/display-list.component';
import { DisplayPreviewComponent } from './components/display-preview/display-preview.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { EditMonitoringComponent } from './components/edit-monitoring/edit-monitoring.component';
import { EditRebootTimeComponent } from './components/edit-reboot-time/edit-reboot-time.component';
import { EditScreenSharingComponent } from './components/edit-screen-sharing/edit-screen-sharing.component';
import { InstallComponent } from './components/install/install.component';
import { ScreenshotComponent } from './components/screenshot/screenshot.component';
import { ResolutionPipe } from './pipes/resolution.pipe';
import { ViewportComponent } from './viewport/viewport.component';

@NgModule({
  declarations: [
    DisplayAddComponent,
    DisplayLicenseCountsComponent,
    DisplayControlComponent,
    DisplayListComponent,
    DisplayAddressComponent,
    AlertsComponent,
    DisplayFieldsComponent,
    ScreenshotComponent,
    ResolutionPipe,
    DisplayDetailsComponent,
    ViewportComponent,
    ActivationNoticeComponent,
    EditAddressComponent,
    EditRebootTimeComponent,
    EditMonitoringComponent,
    BulkActivationComponent,
    InstallComponent,
    DisplayPreviewComponent,
    EditScreenSharingComponent
  ],
  providers: [
    blobDownloaderFactoryProvider
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ApiModule,
    ComponentsModule,
    SchedulesModule,
    SharedModule,
    CommonHeaderModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    MatExpansionModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatRadioModule,
    MatMenuModule,
    NotificationAlertComponent,
    ScrollingModule,
    MatSlideToggleModule,
    MatChipsModule
  ]
})
export class DisplaysModule {
}

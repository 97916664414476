<div rvSpinner="user-settings" [rvShowSpinner]="loading">
  <!-- body -->
  <div id="user-settings" class="mt-4">
    <form #userForm="ngForm" id="forms.userForm" role="form" name="forms.userForm" autocomplete="on" (submit)="save()" novalidate>
      <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
        <strong>{{formError}}</strong> {{apiError}}
      </div>
      @if (editingYourself) {
        <div id="passwordAlert" class="animated" [shown]="!isRiseAuthUser && showChangePassword" [ngClass]="{ 'fadeIn': showChangePassword }">
          <div class="panel-body bg-info u_margin-sm-bottom">
            <p class="u_remove-bottom">
              <span>This account is authenticated by Google.<br/>
                <a href="https://myaccount.google.com/security#signin" target="_blank">Change your password on your Google account.</a>
              </span>
            </p>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label>
              Username:
            </label>
            <div>
              <span id="user-settings-username-label">{{user.username}}</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 text-right">
          @if (editingYourself) {
            <span>
              <a href="#" class="btn btn-default btn-toolbar change-password mb-4 mb-md-0" (click)="toggleChangePassword()">Change password</a>
            </span>
          }
        </div>
      </div>
      @if (editingYourself && isRiseAuthUser && showChangePassword) {
        <div id="passwordForm" class="animated" [ngClass]="{ 'fadeIn': showChangePassword }">
          <password-input
            #currentPassword="ngModel"
            id="user-settings-current-password"
            name="currentPassword"
            [(ngModel)]="userPassword.currentPassword"
            label="Current Password: *"
            placeholder="Enter Current Password"
            (change)="currentPasswordUpdated()"
            required>
          </password-input>
          <password-input
            #newPassword="ngModel"
            name="newPassword"
            label="New Password: *"
            placeholder="Enter New Password"
            [(ngModel)]="userPassword.newPassword"
            [showPasswordMeter]="true" minlength="8" required>
          </password-input>
          <hr />
        </div>
      }
      @if (user.ssouserProvisioningEnabled) {
        <notification-alert
          title="SSO Provisioning Enabled"
          message="Some user settings are disabled and must be managed in your IdP portal." />
      }
      <user-fields [user]="user"></user-fields>
      @if (user.lastLogin) {
        <div class="form-group">
          <label>
            Last Login:
          </label>
          <div>{{user.lastLogin | date:'d-MMM-yyyy h:mm a'}}</div>
        </div>
      }
      @if (!editingYourself) {
        <div class="form-group">
          <label for="user-settings-status">
            Status:
          </label>
          <select name="status" id="user-settings-status"
            class="form-control selectpicker" [(ngModel)]="user.status">
            <option [ngValue]="1">Active</option>
            <option [ngValue]="0">Inactive</option>
          </select>
        </div>
      }
      <div class="form-group" class="pt-2" [shown]="!editingYourself" *requireRole="'sa'">
        @if (!activationLink) {
          <p>
            <a class="madero-link rise-admin-only flex-start" href="#" (click)="createActivationLink()">
              Activation Link
            </a>
          </p>
        }
        @else {
          <div>
            <p><strong>Activation Link:</strong></p>
            <textarea readonly #activationLinkText class="w-100">{{ activationLink }}</textarea>
          </div>
        }
      </div>
      <div class="button-row text-right mt-5">
        <p class="visible-sm visible-xs text-right"><last-modified [changeDate]="user.changeDate" [changedBy]="user.changedBy"></last-modified></p>
        @if (isUserAdmin) {
          <button type="button" id="deleteButton" class="btn btn-danger btn-toolbar pull-left" (click)="deleteUser()">
            Delete
          </button>
        }
        <span class="hidden-sm hidden-xs"><last-modified [changeDate]="user.changeDate" [changedBy]="user.changedBy"></last-modified></span>
        <!-- Indicates cancel or non-destructive action -->
        <button id="cancelButton" type="button" uiSref="apps.user.list" class="btn btn-default btn-toolbar">
          Cancel
        </button>
        <!-- Indicates a successful or positive action -->
        @if (isUserAdmin || editingYourself) {
          <button id="saveButton" form="forms.userForm" type="submit" class="btn btn-primary btn-toolbar" [disabled]="userForm.invalid" >
            Save
          </button>
        }
      </div>
    </form><!-- userDetails -->
  </div>
</div><!--container-->

import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface MenuAction {
  id: string;
  label: string;
  action: () => void;
  requireRole?: string;
  disabled?: boolean;
}

export interface ActionsMenu {
  saveAction?: MenuAction;
  cancelAction?: MenuAction;
  deleteAction?: MenuAction;
  moreOptions?: MenuAction[];
}

@Component({
  selector: 'common-actions',
  templateUrl: './common-actions.component.html',
})
export class CommonActionsComponent {
  private _title: string;

  @Input()
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
    this.titleChange.emit(value);
  }
  @Output() titleChange = new EventEmitter<string>();

  @Input() actions: ActionsMenu;
  @Input() saving = false;
  @Input() changeDate?: Date;
  @Input() changedBy?: string;
}

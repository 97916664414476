import { Injectable } from '@angular/core';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';
import { environment } from 'src/environments/environment';
import { CanvaTypePickerComponent } from '../components/canva-type-picker/canva-type-picker.component';

@Injectable({
  providedIn: 'root'
})
export class CanvaApiService {

  private _canvaApiPromise: Promise<any>;
  private _designButtonPromise: Promise<CanvaButtonApi>;

  constructor(private modalService: ModalService, private analyticsFactory: AnalyticsFactory) {}

  loadCanvaApi() {
    if (this._canvaApiPromise) {
      return this._canvaApiPromise;
    } else {
      this._canvaApiPromise = new Promise<any>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://sdk.canva.com/designbutton/v2/api.js';
        script.onload = function () {
          console.log('Canva loaded');
          if (window.Canva && window.Canva.DesignButton) {
            resolve(window.Canva);
          } else {
            reject();
          }
        };
        script.onerror = reject;
        document.body.appendChild(script);
      });
      return this._canvaApiPromise;
    }
  }

  initializeDesignButtonApi(): Promise<CanvaButtonApi> {
    if (this._designButtonPromise) {
      return this._designButtonPromise;
    } else {
      this._designButtonPromise = this.loadCanvaApi().then(canvaApi => {
        return canvaApi.DesignButton.initialize({
          apiKey: environment.canvaApiKey
        });
      });
      return this._designButtonPromise;
    }
  }

  pickCanvaType() {
    return this.modalService.showDialog(CanvaTypePickerComponent);
  }

  createDesign(): Promise<any>{
    const self = this;
    this.analyticsFactory.track('Canva Design Started');
    const promise = new Promise((resolve, reject) => {
    Promise.all([this.initializeDesignButtonApi(),this.pickCanvaType()])
      .then((result: Array<any>) => {
        const api: CanvaButtonApi = result[0];
        const designType: string = result[1];
        api.createDesign({
          design: {
            type: designType,
          },
          editor: {
            publishLabel: 'Save'
          },
          onDesignPublish: function (options) {
            self.analyticsFactory.track('Canva Design Published', {
              designId: options.designId,
              designTitle: options.designTitle,
            });
            console.log('design published');
            console.log(options);
            resolve(options);
          },
          onDesignClose: function () {
            reject('closed');
          },
        });
      })
      .catch(reject);
    });
    return promise;
  }

  parseDesignId(source: string): string {
    const start = source.indexOf('_');
    return start >= 0 ? source.substring(start + 1).split('.')[0] : '';
  }

  editDesign(designId: string): Promise<any> {
    const self = this;
    this.analyticsFactory.track('Canva Design Edited');
    const promise = new Promise((resolve, reject) => {
      this.initializeDesignButtonApi()
        .then((api) => {
          api.editDesign({
            design: {
              id: designId,
            },
            editor: {
              publishLabel: 'Save'
            },
            onDesignPublish: function (options) {
              self.analyticsFactory.track('Canva Design Updated', {
                designId: options.designId
              });
              console.log('design published');
              console.log(options);
              resolve(options);
            },
            onDesignClose: function () {
              reject('closed');
            },
          });
        })
        .catch(reject);
    });
    return promise;
  }
}

import { trigger, state, style, transition, animate } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { Component, ElementRef, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { TransitionService } from '@uirouter/angular';
import { RouteMetadataService } from 'src/app/common-header/services/route-metadata.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';

export type SidenavState = 'expand' | 'collapse' | 'open' | 'closed';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('sidenavAnimation', [
      state('expand', style({ width: '261px' })),
      state('collapse', style({ width: '61px' })),
      state('open', style({ width: '261px' })),
      state('closed', style({ width: '61px' })),
      transition('expand <=> collapse', animate('0.15s ease-in-out')),
      transition('open => collapse', animate('0.15s ease-in-out'))
    ]),
    trigger('sidenavToggleAnimation', [
      state('expand', style({ transform: 'rotate(0deg)' })),
      state('collapse', style({ transform: 'rotate(180deg)' })),
      transition('expand <=> collapse', animate('0.15s ease-in-out'))
    ]),
    trigger('hideToggleAnimation', [
      state('expand', style({ opacity: 1 })),
      state('collapse', style({ opacity: 1 })),
      state('open', style({ opacity: 0 })),
      state('closed', style({ opacity: 0 })),
      transition('expand <=> closed', animate('0.15s ease-in-out')),
      transition('collapse <=> closed', animate('0.15s ease-in-out')),
      transition('open => expand', animate('0.15s ease-in-out')),
      transition('open => collapse', animate('0.15s ease-in-out'))
    ]),
    trigger('sidenavContentAnimation', [
      state('expand', style({ marginLeft: '261px' })),
      state('collapse', style({ marginLeft: '61px' })),
      state('open', style({ marginLeft: '0' })),
      state('closed', style({ marginLeft: '0' })),
      transition('expand <=> collapse', animate('0.15s ease-in-out')),
      transition('collapse <=> open', animate('0.15s ease-in-out'))
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  sidenavState: SidenavState = 'expand';
  toggleAnimationState: SidenavState = 'expand';
  prevDesktopState: 'expand' | 'collapse' = 'expand';
  isAnimating = false;
  currentRoute: string = '';
  companyMenuOpened = false;
  hideNavigation;

  public readonly isMobile = signal(true);
  private readonly _mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  @ViewChild('contentLayoutBody') contentLayoutBody: ElementRef;

  constructor(
    private media: MediaMatcher,
    private transitionService: TransitionService,
    private rvshareAppDetectionService: RvshareAppDetectionService,
    private routeMetadataService: RouteMetadataService
  ) {
    if (this.rvshareAppDetectionService.isScreenShareApp()) {
      // Rvshare app is always mobile
      this.isMobile.set(true);
      this.sidenavState = 'closed';
    } else {
      // Otherwise, set up the mobile query listener
      this._mobileQuery = this.media.matchMedia('(max-width: 1023px)');
      this.isMobile.set(this._mobileQuery.matches);

      if (this._mobileQuery.matches) {
        this.sidenavState = 'closed';
      }
      this._mobileQueryListener = () => {
        this.isMobile.set(this._mobileQuery.matches);
        if (this._mobileQuery.matches) {
          // Store desktop state before switching to mobile
          if (this.sidenavState === 'expand' || this.sidenavState === 'collapse') {
            this.prevDesktopState = this.sidenavState;
          }
          this.sidenavState = this.companyMenuOpened ? 'open' : 'closed';
        } else {
          this.sidenavState = this.prevDesktopState;
        }
      };
      this._mobileQuery.addEventListener('change', this._mobileQueryListener);
    }
  }

  ngOnInit() {
    this.transitionService.onSuccess({}, (transition) => {
      const toState = transition.to().name;
      this.hideNavigation = toState.indexOf('apps.auth') !== -1 ||
        toState.indexOf('apps.screen-sharing.home') !== -1 ||
        toState.indexOf('apps.billing.invoice') !== -1 ||
        toState.indexOf('apps.billing.unpaid') !== -1;
      if (this.hideNavigation || this.isMobile()) {
        this.sidenavState = 'closed';
      } else if (!this.isMobile()) {
        this.sidenavState = this.prevDesktopState;
      }
      this.currentRoute = toState;
      this.updateTitle();
    });
  }

  ngOnDestroy() {
    if (!this.rvshareAppDetectionService.isScreenShareApp()) {
      this._mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }
  }

  toggleSidenav() {
    if (this.isMobile()) {
      this.sidenavState = this.sidenavState === 'closed' ? 'open' : 'closed';
    } else {
      this.sidenavState = this.sidenavState === 'expand' ? 'collapse' : 'expand';
      this.prevDesktopState = this.sidenavState;
    }
  }

  openMobileMenu() {
    if (this.isMobile()) {
      this.sidenavState = 'open';
    }
  }

  onOpenedChange(opened: boolean) {
    if (this.isMobile()) {
      this.sidenavState = opened ? 'open' : 'closed';
    }
  }

  onAnimationStart(event: any) {
    this.isAnimating = true;
    // Only trigger toggle animation for expand/collapse transitions
    if (event.toState === 'expand' || event.toState === 'collapse') {
      this.toggleAnimationState = event.toState;
    }
  }

  onAnimationDone(event: any) {
    this.isAnimating = false;
  }

  updateTitle(): void {
    const routeMetadata = this.routeMetadataService.getActiveRouteMetadata();
    const titleText = routeMetadata ? `${routeMetadata.title} | ` : '';

    document.title = titleText + 'Rise Vision Apps';
  }

  onHeaderResize(event: { width: number, height: number }) {
    if (this.contentLayoutBody) {
      this.contentLayoutBody.nativeElement.style.setProperty('--common-header-height', event.height + 'px');
    }
  }
}

<div class="container tw-px-8" *requireRole="'sm'" rvSpinner="moderator-join" [rvShowSpinner]="screenSharingService.connecting">
  <div class="tw-py-8 flex flex-row space-between">
    <h1 class="app-header-title" id="title">{{ displayName }}</h1>
    <div>
    <button mat-button class="px-4" (click)="end()">
      <mat-icon svgIcon="sign-out"></mat-icon>
      <div class="u_nowrap">End Session</div>
    </button>
  </div>
  </div>
  @if(screenSharingService.channelError) {
    <div class="alert alert-danger">{{ screenSharingService.channelError }}</div>
  }
  <div class="room-grid">
    <div class="room-controls">
      <screen-sharing-controls
        [isStopped]="stopped"
        [isPaused]="paused"
        [isModerator]="true"
        (startStream)="start()"
        (pauseStream)="pause()"
        (resumeStream)="resume()"
        (stopStream)="stop()"
        (endSession)="end()">
      </screen-sharing-controls>
    </div>
    <div class="room-participants">
      <div class="room-header flex flex-row space-between mb-5">
        <strong>Participants</strong>
        <div class="room-count">{{ screenSharingService.participants?.length ?? '' }}</div>
      </div>
      <div class="room-list">
        @for (user of screenSharingService.participants; track user.memberId; let i = $index) {
          <div class="flex flex-row space-between">
            <div div class="flex flex-row tw-gap-1">
              <button mat-icon-button aria-label="Remove user" class="small-icon-button remove-user-button" (click)="remove(user.memberId)">
                <mat-icon svgIcon="close" class="small heavy"></mat-icon>
              </button>
              <mat-icon svgIcon="user" class="small-icon"></mat-icon>
              {{ user.memberDisplayName }}
              {{ user.memberId === screenSharingService.memberId ? ' (me)' : '' }}
            </div>
            <button
              mat-flat-button
              color="primary"
              [disabled]="screenSharingService.sharing?.memberId === user.memberId || screenSharingService.invitedId === user.memberId"
              (click)="share(user.memberId)"
            >
              @if(screenSharingService.invitedId === user.memberId ) {
                <mat-spinner diameter="15" class="white"></mat-spinner>
              } @else {
                {{ i === 0 ? 'Share' : 'Invite' }}
              }
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<div class="tw-pt-8" rvSpinner="billing-loader" [rvShowSpinner]="subscriptions.loadingItems || invoices.loadingItems">
  <div class="form-group mb-3">
    <label>Billing Settings</label>
  </div>
  <div class="border-container" *requireRole="'ua'">
    <div class="panel-body">
      <div class="flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Billing Email:</label>
          <div class="mx-5">
            <span [shown]="company.billingContactEmails">{{company.billingContactEmails?.join(', ')}}</span>
            <span [shown]="!company.billingContactEmails">Not set. Please enter a billing email.</span>
          </div>
          <span class="ml-auto">
            <a class="madero-link u_clickable" uiSref="apps.company.details">Edit</a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group mt-5 mb-3">
    <label>Subscriptions</label>
  </div>
  <div>
    @if (subscriptions.items?.list.length
      && billingUtils.hasTaxExemptionExpired(subscriptions.items.list[0].customer)) {
      <notification-alert
        title="Tax exemption expired"
        type="warning">
        <p>Please <a class="madero-link u_clickable" uiSref="apps.billing.subscription" [uiParams]="{subscriptionId: subscriptions.items.list[0].subscription.id}">click here</a> to update your tax exemption certificate.</p>
      </notification-alert>
    }
  </div>
  <div id="errorBox" [shown]="subscriptions.apiError" class="madero-style alert alert-danger" role="alert">
    <p><strong>{{subscriptions.errorMessage}}</strong></p>
    {{subscriptions.apiError}}
  </div>
  <div class="scrollable-list horizontal-scroll border-container mt-0 u_margin-md-bottom" scrollingList (scrollEndEvent)="subscriptions.load()">
    <table id="subscriptionsListTable" class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell col-xs-4">Subscription</th>
          <th class="table-header__cell col-xs-2">Status</th>
          <th class="table-header__cell col-xs-2">Renewal Date</th>
          <th class="table-header__cell col-xs-2">Plan Amount</th>
          <th class="table-header__cell col-xs-2">Ship To</th>
        </tr>
      </thead>
      <tbody class="table-body">
        @if (!currentPlanService.canModifyPlan) {
          <tr class="table-body__row">
            <td class="table-body__cell font-weight-bold">
              Subscription managed by {{currentPlanService.currentPlan.parentPlanCompanyName || "the Parent Company"}}
            </td>
            <td class="table-body__cell">
              <span class="u_capitalize text-success">
                Active
              </span>
            </td>
            <td class="table-body__cell">
              -
            </td>
            <td class="table-body__cell">
              -
            </td>
            <td class="table-body__cell">
            </td>
          </tr>
        }
        @for (item of subscriptions.items.list; track item) {
          <tr class="table-body__row">
            <td class="table-body__cell font-weight-bold">
              <div class="flex-row">
                <div>
                  @if (showSubscriptionLink(item.subscription)) {
                    <a class="madero-link u_clickable" uiSref="apps.billing.subscription" [uiParams]="{subscriptionId: item.subscription.id}">{{item.subscription | subscriptionDescription}}</a>
                  }
                  @else {
                    <span>{{item.subscription | subscriptionDescription}}</span>
                  }
                </div>
                @if (item.subscription.has_scheduled_changes) {
                  <streamline-icon ariaLabel="More information" matTooltip="Pending Changes" matTooltipPosition="right" matTooltipClass="font-weight-bold" runZone name="info" width="14" height="14" class="aligner rise-blue pl-2"></streamline-icon>
                }
                @if (item.subscription.cancelled_at) {
                  <streamline-icon ariaLabel="More information" matTooltip="Pending Cancellation" matTooltipPosition="right" matTooltipClass="font-weight-bold" runZone name="info" width="14" height="14" class="aligner rise-blue pl-2"></streamline-icon>
                }
              </div>
            </td>
            <td class="table-body__cell">
              <status-name [subscription]="item.subscription"></status-name>
            </td>
            <td class="table-body__cell">
              {{item.subscription.current_term_end * 1000 | date:'d-MMM-yyyy'}}
            </td>
            <td class="table-body__cell">
              {{item.subscription.plan_amount / 100 | currency}}
            </td>
            <td class="table-body__cell">
              @if (item.subscription.customer_id !== item.subscription.cf_ship_to_company) {
                <span>{{item.subscription.cf_ship_to_company_name || item.subscription.shipping_address?.company || item.subscription.cf_ship_to_company}}</span>
              }
            </td>
          </tr>
        }
        <tr [shown]="subscriptions.items.list.length === 0 && currentPlanService.canModifyPlan">
          <td colspan="5" class="text-center">You haven't Subscribed to any Products yet.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="form-group mt-5 mb-3">
    <label>Billing History</label>
    <span [shown]="invoices.items.list.length > 0">
      (<a class="madero-link" uiSref="apps.billing.unpaid" [uiParams]="{token: invoiceService.invoiceToken}" target="_blank">View Unpaid Invoices</a>)
    </span>
  </div>
  <div id="errorBox" [shown]="invoices.apiError" class="madero-style alert alert-danger" role="alert">
    <p><strong>{{invoices.errorMessage}}</strong></p>
    {{invoices.apiError}}
  </div>
  <div id="errorBox" [shown]="invoiceService.apiError" class="madero-style alert alert-danger" role="alert">
    <strong>{{invoiceService.apiError}}</strong>
  </div>
  <div class="scrollable-list horizontal-scroll border-container mt-0 u_margin-md-bottom" scrollingList (scrollEndEvent)="invoices.load()">
    <table id="invoicesListTable" class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell col-xs-1">Status</th>
          <th class="table-header__cell col-xs-1">Date</th>
          <th class="table-header__cell col-xs-2">Description</th>
          <th class="table-header__cell col-xs-5">Amount</th>
          <th class="table-header__cell col-xs-2">Invoice</th>
          <th class="table-header__cell col-xs-1">&nbsp;</th>
          <th class="table-header__cell col-xs-2">Ship To</th>
        </tr>
      </thead>
      <tbody class="table-body">
        @for (item of invoices.items.list; track item) {
          <tr class="table-body__row">
            <td class="table-body__cell">
              <streamline-icon ariaLabel="Unpaid invoice" class="status unpaid" name="exclamation" width="5" height="15" [shown]="item.invoice.status !== 'paid'"></streamline-icon>
              <streamline-icon ariaLabel="Paid invoice" class="status paid" name="checkmark" width="17" height="14" [shown]="item.invoice.status === 'paid'"></streamline-icon>
            </td>
            <td class="table-body__cell">{{item.invoice.date * 1000 | date:'d-MMM-yyyy'}}</td>
            <td class="table-body__cell font-weight-bold">
              Invoice #{{item.invoice.id}}
            </td>
            <td class="table-body__cell">
              {{(item.invoice.amount_due || item.invoice.total) / 100 | currency}}
            </td>
            <td class="table-body__cell">
              <a tabindex="0" class="madero-link u_clickable" (click)="invoiceService.downloadInvoice(item.invoice.id)" (keyup.enter)="invoiceService.downloadInvoice(item.invoice.id)">
                <img src="../images/icon-download.svg" width="20" height="20">
              </a>
            </td>
            <td class="table-body__cell py-0">
              @if (item.invoice.status !== 'paid') {
                <a class="btn btn-default btn-pay-now" uiSref="apps.billing.invoice" [uiParams]="{invoiceId: item.invoice.id, token: invoiceService.invoiceToken}" target="_blank">Pay Now</a>
              }
            </td>
            <td class="table-body__cell">
              @if (item.invoice.billing_address?.company !== item.invoice.shipping_address?.company) {
                <span>{{item.invoice.shipping_address?.company}}</span>
              }
            </td>
          </tr>
        }
        <tr [shown]="invoices.items.list.length === 0">
          <td colspan="6" class="text-center">You do not have any invoices yet.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="u_margin-lg text-center">
    <label>Need help with something?</label>
    <p><a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360041149991-Payment-instructions-and-common-questions-" target="_blank">Common Billing & Payment Questions</a></p>
  </div>
</div><!--container-->

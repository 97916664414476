import { Component, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { SocialMediaService } from '../services/social-media.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

const FB_PAGE_PROVIDER = 'facebook-business';
const IG_PAGE_PROVIDER = 'instagram-business';

const FB_PAGE_SCOPE = "pages_show_list,pages_read_engagement,pages_read_user_content,business_management";
const IG_PAGE_SCOPE = "instagram_business_basic";

export interface Feed {
  userAccount?: string;
  pageId?: string;
  pageName?: string;
  userDisplayName?: string;
}

export enum FeedType {
  FB_PAGE = 'fb_page',
  IG_PAGE = 'ig_page',
}

@Component({
  selector: 'template-component-social-media-followers',
  templateUrl: './social-media-followers.component.html',
  styleUrls: ['./social-media-followers.component.scss'],
})
export class SocialMediaFollowersComponent {
  FeedType: typeof FeedType = FeedType;
  public readonly FEEDS_COUNT = Object.keys(FeedType).length;

  public componentId: string;
  public availableFeeds = [];
  public feeds = new Map<FeedType, Feed>();
  public currentView: string;
  public selectedFeed: {type: FeedType, feed: Feed};
  public availablePages: { id: string; name: string; }[];
  public selectedPage: { id: string; name: string; };
  public spinner: boolean;
  public userAccount: string;
  public authenticateFailed: boolean;
  public authStatusFailed = new Set<FeedType>();
  public revokeFailed: boolean;
  public revokeFailedProviderName: string;
  public pagesApiFailed: boolean;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private componentsFactory: ComponentsService,
    private attributeDataService: AttributeDataService,
    private authService: OAuthService,
    private socialMediaService: SocialMediaService,
    private modalService: ModalService,
    private templateEditorService: TemplateEditorService,
    private featuresService: FeaturesService,
  ) {
    this.userAccount = this.authService.getUserIdentifier(null);

    this.componentsFactory.registerDirective({
      type: 'rise-social-media-followers',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = componentsFactory.selected.id;
        this.selectedPage = null;
        this.authenticateFailed = false;
        this.revokeFailed = false;
        this.pagesApiFailed = false;
        this.spinner = false;
        this.currentView = null;
        this.authStatusFailed.clear();
        this.load();
        this._checkAuthStatusForAllFeeds();
      },
      onBackHandler: () => {
        if (this.currentView) {
          this.currentView = null;
          return true;
        }
      },
      getLabel: (): string => {
        if (this.currentView) {
          return 'Add Feed';
        }
      },
    });
  }

  load() {
    const sources = this.attributeDataService.getAvailableAttributeData(this.componentId, 'sources') || new Map<FeedType, Feed>();
    this.feeds = this._sourcesToFeeds(sources);
  }

  save() {
    const sources = this._feedsToSources(this.feeds);
    this.attributeDataService.setAttributeData(this.componentId, 'sources', sources);
    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
  }

  _getProvider(feedType: FeedType) {
    switch (feedType) {
      case FeedType.FB_PAGE:
        return FB_PAGE_PROVIDER;
      case FeedType.IG_PAGE:
        return IG_PAGE_PROVIDER;
      default:
        return FB_PAGE_PROVIDER;
    }
  }

  _getScope(feedType: FeedType) {
    switch (feedType) {
      case FeedType.FB_PAGE:
        return FB_PAGE_SCOPE;
      case FeedType.IG_PAGE:
        return IG_PAGE_SCOPE;
      default:
        return '';
    }
  }

  _getProviderName(feedType: FeedType) {
    switch (feedType) {
      case FeedType.FB_PAGE:
        return 'Facebook business account';
      case FeedType.IG_PAGE:
        return 'Instagram';
      default:
        return '';
    }
  }

  refreshUI() {
    this.changeDetectorRef.detectChanges();
  }

  addFeed(feedType: FeedType) {
    if (!this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SOCIAL_MEDIA_FOLLOWERS)) {
      this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SOCIAL_MEDIA_FOLLOWERS);
      return;
    }

    this.currentView = 'add_feed';
    this.selectedFeed = {
      type: feedType,
      feed: {},
    };
  }

  async confirmDisconnect(feedType: FeedType) {
    const disconnectMessage = `Any content that is using this account will stop working.`;

    try {
      await this.modalService.confirm(`Disconnect from ${this._getProviderName(feedType)}`, disconnectMessage);
      this._disconnect(feedType);
    } catch { }
  }

  removeFeed(feedType: FeedType) {
    this.feeds.delete(feedType);
    this.authStatusFailed.delete(feedType);
    this.save();
  }

  async connect() {
    await this._authenticate();

    if (this.authenticateFailed) {
      return;
    }

    if (this.selectedFeed.type === FeedType.FB_PAGE) {
      await this._loadFacebookPages();
    } else {
      this._addToFeeds(this.selectedFeed.type, this.selectedFeed.feed);
      this.save();
      this.currentView = null;
    }
  }

  async addSelectedPage() {
    try {
      this.pagesApiFailed = false;
      this.spinner = true;

      if (!this.selectedPage?.id) {
        throw new Error('Page ID is missing');
      }

      const pageSelected = await this.socialMediaService.selectPage(this.userAccount, this.selectedPage.id);
      if (pageSelected) {
        this.selectedFeed.feed.pageId = this.selectedPage.id;
        this.selectedFeed.feed.pageName = this.selectedPage.name;
        this._addToFeeds(this.selectedFeed.type, this.selectedFeed.feed);
        this.currentView = null;
        this.save();
      } else {
        throw new Error('Backend error');
      }
    } catch (error) {
      this.pagesApiFailed = true;
      console.error(`Failed to select page ${this.selectedPage?.id}`, error.message);
    } finally {
      this.spinner = false;
    }
  }

  _addToFeeds(feedType: FeedType, feed: Feed) {
    this.feeds.set(feedType, feed);
  }

  async _loadFacebookPages() {
    try {
      this.spinner = true;
      this.pagesApiFailed = false;
      this.availablePages = [];
      this.selectedPage = null;
      this.currentView = 'select_page';

      this.availablePages = await this.socialMediaService.getPages(this.userAccount);
    } catch (error) {
      this.pagesApiFailed = true;
    } finally {
      this.spinner = false;
      this.refreshUI();
    }
  }

  private async _connectAccount(provider, scope) {
    try {
      return await this.authService.getConnectionStatus(provider, scope);
    } catch {
      return await this.authService.authenticate(provider, scope);
    }
  }

  async _authenticate() {
    try {
      this.spinner = true;
      this.authenticateFailed = false;
      this.revokeFailed = false;

      const provider = this._getProvider(this.selectedFeed.type);
      const scope = this._getScope(this.selectedFeed.type);
      const feed = this.selectedFeed.feed;

      const authResult = await this._connectAccount(provider, scope);

      feed.userDisplayName = authResult.username;
      feed.userAccount = this.userAccount;
    } catch (err) {
      console.error(`Failed to connect to ${this.selectedFeed?.type}`, err);
      this.authenticateFailed = true;
      this.currentView = null;
    } finally {
      this.spinner = false;
      this.refreshUI();
    }
  }

  async _disconnect(feedType: FeedType) {
    try {
      this.revokeFailed = false;
      this.revokeFailedProviderName = this._getProviderName(feedType);

      // only user who added the feed have the right to disconnect it
      const feed = this.feeds.get(feedType);
      if (!feed || feed.userAccount !== this.userAccount) {
        this.revokeFailed = true;
        return;
      }

      this.spinner = true;

      this.removeFeed(feedType);

      this.templateEditorService.hasUnsavedChanges = true;

      await this.templateEditorService.save();

      const revoked = await this.socialMediaService.revoke(this.userAccount, this._getProvider(feedType));

      if (!revoked) {
        throw new Error('Failed to revoke account');
      }

    } catch (error) {
      console.error(`Failed to revoke account`, error.message);
      this.revokeFailed = true;
    } finally {
      this.spinner = false;
      this.refreshUI();
    }
  }

  async _checkAuthStatusForAllFeeds() {
    this.authStatusFailed.clear();
    for (const [feedType, feed] of this.feeds) {
      try {
        await this.authService.getConnectionStatus(this._getProvider(feedType), this._getScope(feedType));
      } catch {
        this.authStatusFailed.add(feedType);
      }
    }
  }

  _feedsToSources(feeds: Map<string, Feed>) {
    return Array.from(feeds, ([name, value]) => ({
      type: name,
      account: value.userAccount,
      pageId: value.pageId,
      pageName: value.pageName,
      userDisplayName: value.userDisplayName,
    }));
  }

  _sourcesToFeeds(sources: any[]) {
    const feeds = new Map<FeedType, Feed>();
    sources.forEach(source => {
      feeds.set(source.type, {
        userAccount: source.account,
        pageId: source.pageId,
        pageName: source.pageName,
        userDisplayName: source.userDisplayName,
      });
    });
    return feeds;
  }
}

import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';

import { environment } from 'src/environments/environment';

import { ModalService } from 'src/app/components/modals/modal.service';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { PowerBIService } from '../services/powerbi.service';

const POWER_BI_PROVIDER = 'ms-pbi';
const WORKSPACE_SCOPE = 'https://analysis.windows.net/powerbi/api/Workspace.Read.All';
const REPORT_SCOPE = 'https://analysis.windows.net/powerbi/api/Report.Read.All';
const DASHBOARD_SCOPE = 'https://analysis.windows.net/powerbi/api/Dashboard.Read.All';
const REQUEST_SCOPES = `${WORKSPACE_SCOPE} ${REPORT_SCOPE} ${DASHBOARD_SCOPE}`;
enum States {
  NotConfigured,
  ConfiguredByMe,
  ConfiguredByOthers,
}

export enum ContentType {
  REPORT = 'report',
  DASHBOARD = 'dashboard',
}

@Component({
  selector: 'template-component-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.scss']
})
export class PowerBIComponent {

  public states: typeof States = States;
  public ContentType: typeof ContentType = ContentType;

  public componentId: string;

  public spinner: boolean;
  public revokeFailed: boolean;
  public authenticateFailed: boolean;
  public userAccount: string;
  public userAuthorized: boolean;
  public componentDisconnected: boolean;
  public componentAccount: string;
  public componentAccountUsername: string;
  public signature: string;

  public contentType: ContentType;

  public groupId: string;
  public selectedGroup: string;
  public selectedGroupName:string;
  public groups = [];
  public groupsFailed: boolean;

  public dashboardId: string;
  public selectedDashboard: string;
  public selectedDashboardName: string;
  public dashboards = null;
  public dashboardsFailed: boolean;

  public reportId: string;
  public selectedReport: string;
  public selectedReportName: string;
  public reports = null;
  public reportsFailed: boolean;

  public refresh: string;

  get sameAccount(): boolean {
    return this.userAccount === this.componentAccount;
  }

  get state(): States {
    // Chart: https://docs.google.com/document/d/1xKSf_nWXSOH6rlnsJDDjQD5yNVAtL2-SqP3t0gy3__U/edit#bookmark=id.b0rh1mszfnaf

    if(!this.userAuthorized && (!this.componentAccount || this.componentAccount && this.sameAccount)) {
      return States.NotConfigured;
    }

    if(this.userAuthorized && (!this.componentAccount || this.componentAccount && this.sameAccount)) {
      return States.ConfiguredByMe;
    }

    return States.ConfiguredByOthers;
  }

  constructor(
    private modalService: ModalService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private attributeDataService: AttributeDataService,
    private componentsFactory: ComponentsService,
    private templateEditorService: TemplateEditorService,
    private powerBiService: PowerBIService,
    private authService: OAuthService) {

    this.spinner = false;
    this.revokeFailed = false;
    this.authenticateFailed = false;
    this.userAccount = this.authService.getUserIdentifier(POWER_BI_PROVIDER);
    this.userAuthorized = false;
    this.componentDisconnected = false;
    this.componentAccount = null;
    this.componentAccountUsername = null;
    this.selectedGroup = '';
    this.selectedGroupName = '';
    this.groups = [];
    this.groupsFailed = false;
    this.selectedDashboard = '';
    this.selectedDashboardName = '';
    this.dashboards = null;
    this.reports = null;
    this.dashboardsFailed = false;
    this.reportsFailed = false;
    this.refresh = '0';

    this.componentsFactory.registerDirective({
      type: 'rise-powerbi',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = componentsFactory.selected.id;
        this.load();
      }
    });
  }

  load() {
    this._resetDashboards();
    this._resetReports();

    this.componentAccount = this.attributeDataService.getAvailableAttributeData(this.componentId, 'account');
    this.groupId = this.attributeDataService.getAvailableAttributeData(this.componentId, 'groupId');
    const contentId = this.attributeDataService.getAvailableAttributeData(this.componentId, 'contentId');
    const contentName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'contentName');
    this.contentType = this.attributeDataService.getAvailableAttributeData(this.componentId, 'contentType') || ContentType.DASHBOARD;
    this.signature = this.attributeDataService.getAvailableAttributeData(this.componentId, 'signature');
    const refresh = this.attributeDataService.getAvailableAttributeData(this.componentId, 'refresh');
    this.refresh = refresh ? refresh.toString() : '0';

    if (this.componentAccount) {
      this.selectedGroup = this.groupId || '';
      this.selectedGroupName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'groupName') || '';

      if (this.contentType === ContentType.DASHBOARD) {
        this.dashboardId = contentId;
        this.selectedDashboard = contentId || '';
        this.selectedDashboardName = contentName || '';
      } else if (this.contentType === ContentType.REPORT) {
        this.reportId = contentId;
        this.selectedReport = contentId || '';
        this.selectedReportName = contentName || '';
      }
    } else {
      this.selectedGroup = '';
      // Set default to reports for not configured components
      this.contentType = ContentType.REPORT;
    }

    this._validateCredentials();
  }

  saveAttributeData() {
    // save data only if changed by owner or if account is disconnected
    if (this.sameAccount || !this.componentAccount) {
      this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
      this.attributeDataService.setAttributeData(this.componentId, 'account', this.componentAccount);
      this.attributeDataService.setAttributeData(this.componentId, 'signature', this.signature);
      this.attributeDataService.setAttributeData(this.componentId, 'groupId', this.groupId || '');
      this.attributeDataService.setAttributeData(this.componentId, 'groupName', this.selectedGroupName || '');
      this.attributeDataService.setAttributeData(this.componentId, 'contentType', this.contentType || '');
      if (this.contentType === ContentType.DASHBOARD) {
        this.attributeDataService.setAttributeData(this.componentId, 'contentId', this.dashboardId || '');
        this.attributeDataService.setAttributeData(this.componentId, 'contentName', this.selectedDashboardName || '');
      } else if (this.contentType === ContentType.REPORT) {
        this.attributeDataService.setAttributeData(this.componentId, 'contentId', this.reportId || '');
        this.attributeDataService.setAttributeData(this.componentId, 'contentName', this.selectedReportName || '');
      }
    }

    this.attributeDataService.setAttributeData(this.componentId, 'refresh', this.refresh ? parseInt(this.refresh, 10) : 0);
  }

  saveAccount(account, signature) {
    this.componentAccount = account;
    this.signature = signature;
    this.saveAttributeData();
  }

  private _validateCredentials() {
    this.spinner = true;
    this.userAuthorized = false;

    // get username of a user who configured the component
    return this.authService.getUsername(POWER_BI_PROVIDER, this.componentAccount)
      .then(({authenticated, username}) => {
        this.componentAccountUsername = username;

        if (this.componentAccount && !authenticated) {
          this.componentDisconnected = true;
        }
      })
      .catch(() => {
        console.log('PowerBI failed to get username');
      })
      // get auth status of the signed in user
      .then(() => this.authService.getConnectionStatus(POWER_BI_PROVIDER, REQUEST_SCOPES))
      .then((status) => {
        this.userAuthorized = true;

        // Component not yet configured. This is the first time.
        if (!this.componentAccount) {
          return this.powerBiService.getSignature(this.userAccount, POWER_BI_PROVIDER)
          .then((signature)=>{
            this.saveAccount(this.userAccount, signature);
          });
        }

        return Promise.resolve();

      })
      .then(() => this._populateSelections())
      .catch(() => {
        console.log('PowerBI Account not connected');
      })
      .finally(() => {
        this.spinner = false;

        this.changeDetectorRef.detectChanges();
      });
  }

  private _populateSelections() {
    if (!this.sameAccount) {
      return;
    }

    this.groupsFailed = false;
    this.dashboardsFailed = false;
    this.reportsFailed = false;

    return this._getGroups()
      .then(() => this._loadDashboardsAndReports())
      .catch((err) => {
        console.log('Failed to populate selections', err);
        this.groupsFailed = this.groups.length === 0;
      });
  }

  private _getGroups() {
    return this.powerBiService.getGroups(this.userAccount)
    .then((groups: any) => {
      this.groups = groups;

      if (!this.selectedGroup) {
        this.selectedGroup = 'my-workspace';
        this._updateGroup(this.selectedGroup);
      }
    });
  }

  private async _getDashboards() {
    try {
      this.dashboardsFailed = false;
      this.dashboards = await this.powerBiService.getDashboards(this.userAccount, this.selectedGroup);
  
      if (this.dashboards?.length === 1) {
        this.selectedDashboard = this.dashboards[0].id;
        this.selectedDashboardChanged();
      }
    } catch (error) {
      console.error('Failed to get dashboards:', error);
      this.dashboardsFailed = true;
      throw error;
    }
  }

  private async _getReports() {
    try {
      this.reportsFailed = false;
      this.reports = await this.powerBiService.getReports(this.userAccount, this.selectedGroup);

      if (this.reports?.length === 1) {
        this.selectedReport = this.reports[0].id;
        this.selectedReportChanged();
      }
    } catch (error) {
      console.error('Failed to get reports:', error);
      this.reportsFailed = true;
      throw error;
    }
  }

  _connectAccount() {
    this.spinner = true;

    return this.authService.authenticate(POWER_BI_PROVIDER, REQUEST_SCOPES)
      .then((authResult) => {
        this.userAuthorized = true;
        this.authenticateFailed = false;
        this._resetAccount(false);

        this.componentAccountUsername = authResult.username;
        this.userAccount = this.authService.getUserIdentifier(POWER_BI_PROVIDER);
      })
      .then(() => this.powerBiService.getSignature(this.userAccount, POWER_BI_PROVIDER))
      .then((signature) => this.saveAccount(this.userAccount, signature))
      .then(() => this._populateSelections())
      .catch((err) => {
        console.log('Failed to connect', err);
        this.authenticateFailed = true;
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  _revokeAccount() {
    return this.authService.revoke(POWER_BI_PROVIDER)
      .then((revoked: boolean) => {
        this.userAuthorized = false;
        if (!revoked) {
          console.log('Token could not be revoked');

          this.revokeFailed = true;
        }
      })
      .catch((err) => {
        console.log('Failed to revoke account', err.message);
      });
  }

  _resetAccount(revoke) {
    this._resetDashboards();
    this._resetReports();
    this._resetGroup();
    this.selectedGroupChanged();
    this.componentDisconnected = false;

    this.spinner = true;

    if (!revoke) {
      return;
    }

    this.templateEditorService.hasUnsavedChanges = true;

    this.templateEditorService.save()
      .then(() => {
        return this._revokeAccount();
      })
      .catch((err) => {
        console.log('Failed to save presentation', err.message);
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  _resetGroup() {
    this.groups = [];
    this.groupsFailed = false;
    this.groupId = '';
    this.selectedGroup = '';
    this.selectedGroupName = '';
  }

  _resetDashboards() {
    this.dashboards = null;
    this.dashboardsFailed = false;
    this.dashboardId = '';
    this.selectedDashboard = '';
    this.selectedDashboardName = '';
  }

  _resetReports() {
    this.reports = null;
    this.reportsFailed = false;
    this.reportId = '';
    this.selectedReport = '';
    this.selectedReportName = '';
  }

  _updateGroup(selectedGroup) {
    let name = '';

    if (selectedGroup === 'my-workspace') {
      name = 'My Workspace';
    } else if (selectedGroup) {
      const selected = this.groups.find(group => group.id === selectedGroup);
      if (selected) {
        name = selected.name;
      }
    }

    this.groupId = selectedGroup;
    this.selectedGroupName = name;
  }

  _updateDashboard(selectedDashboard) {
    let name = '';

    if (selectedDashboard) {
      const selected = this.dashboards.find(dashboard => dashboard.id === selectedDashboard);
      if (selected) {
        name = selected.displayName;
      }
    }

    this.dashboardId = selectedDashboard;
    this.selectedDashboardName = name;
  }

  _isDashboardsLoaded() {
    return this.dashboards !== null;
  }

  _isReportsLoaded() {
    return this.reports !== null;
  }

  private _updateReport(selectedReport) {
    let name = '';

    if (selectedReport) {
      const selected = this.reports.find(report => report.id === selectedReport);
      if (selected) {
        name = selected.displayName;
      }
    }

    this.reportId = selectedReport;
    this.selectedReportName = name;
  }

  selectedGroupChanged() {
    this._updateGroup(this.selectedGroup);
    this._resetDashboards();
    this._resetReports();

    if (this.selectedGroup) {
      this.spinner = true;

      this._loadDashboardsAndReports()
      .then(() => {
        this.saveAttributeData();

        this.changeDetectorRef.detectChanges();
      })
      .catch((err) => {
        console.log(`Failed to populate ${this.contentType}`, err);
      })
      .finally(() => {
        this.spinner = false;
      });
    } else {
      this.saveAttributeData();
    }
  }

  selectedDashboardChanged() {
    this._updateDashboard(this.selectedDashboard);

    this.saveAttributeData();
  }

  selectedReportChanged() {
    this._updateReport(this.selectedReport);

    this.saveAttributeData();
  }

  refreshChanged() {
    this.saveAttributeData();
  }

  async contentTypeChanged() {
    this.spinner = true;
    try {
      this.saveAttributeData();
      await this._loadDashboardsAndReports();
    } catch (err) {
      console.log(`Failed to populate ${this.contentType}`);
    } finally {
      this.changeDetectorRef.detectChanges();
      this.spinner = false;
    }
  }

  async _loadDashboardsAndReports() {
    if (this.contentType === ContentType.DASHBOARD && !this._isDashboardsLoaded()) {
      await this._getDashboards();
    } else if (this.contentType === ContentType.REPORT && !this._isReportsLoaded()) {
      await this._getReports();
    }
  }

  confirmConnect() {
    this._connectAccount();
  }

  confirmChange() {
    this._connectAccount();
  }

  confirmDisconnect() {
    let disconnectMessage = 'Any content that is using this PowerBI account will stop working.';

    this.modalService.confirm('Disconnect from PowerBI', disconnectMessage)
      .then(() => {
        this._resetAccount(true);
      })
      .catch(() => {});
  }

}
